import './ProgressBar.css'

interface Props {
  value: number;
  color?: 'default' | 'green';
}

const ProgressBar: React.FC<Props> = ({ value, color = 'default' }) => {
  const displayPercentage = Math.round(value * 100);
  return (
    <div>
      <span>{displayPercentage}% complete</span>
      <progress className={`progress progress--${color}`} value={value} />
    </div>
  )
}

export default ProgressBar