import { useEffect, useState } from "react";
import { Automation, Step } from "../../../api/process";
import Input from "../../../components/input/Input";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { AgentTool } from "../../../api/ai/admin";
import { api } from "../../../api";
import "./ConfigureStepAgent.css";
import { title } from "case";

interface Props {
  step: Step;
  onSave: (step: Step) => void;
}

const ConfigureStepAgent: React.FC<Props> = ({ step, onSave }) => {
  const [externalTools, setExternalTools] = useState<AgentTool[]>([]);
  const [internalTools, setInternalTools] = useState<AgentTool[]>([]);
  const [agentConfig, setAgentConfig] = useState<Automation["agent"]>(step.automation?.agent);

  const getTools = async () => {
    const response = await api.ai.admin.getAgentTools();
    if (response.data) {
      setExternalTools(response.data.externalTools);
      setInternalTools(response.data.internalTools);
    }
  };

  useEffect(() => {
    setAgentConfig(step.automation?.agent);
    getTools();
  }, [step]);

  return (
    <div>
      <div className="configure-step-agent__header">
        <SparklesIcon />
        <h3>Configure Step Agent</h3>
      </div>
      <div>
        <Input
          id="agent-enabled"
          labelText="Agent Enabled"
          value={(!!agentConfig).toString()}
          onChange={(value) => {
            if (value === "false") {
              setAgentConfig(undefined);
            } else {
              setAgentConfig({
                enabledTools: agentConfig?.enabledTools || [],
                ...agentConfig,
              });
            }
          }}
          type="checkbox"
        />
      </div>
      {!!agentConfig && (
        <div className="configure-step-agent__tools">
          <div className="configure-step-agent__tools-container">
            <div className="configure-step-agent__external-tools">
              <h4>External Tools</h4>
              <p>
                External tools are external services that can be used by the agent. They are not built into the platform
                and must be enabled for the agent to use them in this step.
              </p>
              <div className="configure-step-agent__tools-list">
                {externalTools.map((tool) => {
                  const isEnabled = !!agentConfig?.enabledTools?.some((t) => t.toolId === tool.id);
                  return (
                    <div key={tool.name} className="configure-step-agent__tool-item">
                      <div className="configure-step-agent__tool-header">
                        <h5>{title(tool.name)}</h5>
                      </div>
                      <p className="configure-step-agent__tool-description">{tool.description}</p>
                      <Input
                        id={`tool-${tool.name}`}
                        type="checkbox"
                        value={isEnabled.toString()}
                        onChange={(value) => {
                          if (value === "true") {
                            setAgentConfig({
                              ...agentConfig,
                              enabledTools: [...agentConfig.enabledTools, { toolId: tool.id, hardcodedArgs: {} }],
                            });
                          } else {
                            setAgentConfig({
                              ...agentConfig,
                              enabledTools: agentConfig.enabledTools.filter((t) => t.toolId !== tool.id),
                            });
                          }
                        }}
                        labelText={`Enable ${title(tool.name)}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="configure-step-agent__internal-tools">
              <h4>Internal Tools</h4>
              <p>
                Internal tools are built into the platform and cannot be disabled. They allow the agent to perform
                actions on the platform.
              </p>
              <div className="configure-step-agent__tools-list">
                {internalTools.map((tool) => (
                  <div key={tool.name} className="configure-step-agent__tool-item">
                    <h5>{title(tool.name)}</h5>
                    <p>{tool.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="button__group">
        <button
          className="button button--secondary"
          onClick={() => {
            onSave(step);
          }}
        >
          Cancel
        </button>
        <button
          className="button"
          onClick={() => {
            if (!agentConfig) {
              onSave({
                ...step,
                // @ts-ignore
                automation: null,
              });
              return;
            }
            onSave({
              ...step,
              automation: {
                type: "AGENT",
                agent: agentConfig,
              },
            });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ConfigureStepAgent;
