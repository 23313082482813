import { Fragment, useEffect, useState } from 'react';
import { Process, Step } from '../../../../api/process';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import { BoltIcon, ClockIcon, EyeIcon, PencilIcon, SparklesIcon } from '@heroicons/react/24/outline';
import './EditStep.css';
import Modal from '../../../../components/modal/Modal';
import EditGuidanceNotes from './EditGuidanceNotes';
import EditStepData from './EditStepData';
import ConfigureAutomation from '../../configura-automation/ConfigureAutomation';
import TagSelect from '../../../../components/input/TagSelect';
import EditDueDate from './EditDueDate';
import WorkInstructionIcon from '../../../../components/icon/WorkInstructionIcon';
import DataCollectedIcon from '../../../../components/icon/DataCollected';
import ProcessitemsIcon from '../../../../components/icon/ProcessItems';
import RiskLogIcon from '../../../../components/icon/RiskLogIcon';
import EditStepRisks from './step-risks/EditStepRisks';
import CaseStepComponent from '../../../case/case-step/CaseStep';
import { Case, CaseStep } from '../../../../api/case';
import ConfigureStepAgent from '../../configure-agent/ConfigureStepAgent';

interface Props {
  process: Process;
  selectedStepId: string | undefined;
  allSteps: Step[];
  handleStepUpdate: (updatedStep: Step) => void;
}

type EditableData = Pick<
  Step,
  'name' | 'type' | 'ownerGroups' | 'taxonomyLabel' | 'requireProcessItemsHere'
>;

const EditStep: React.FC<Props> = ({ process, selectedStepId, allSteps, handleStepUpdate }) => {
  const [editableData, setEditableData] = useState<EditableData>({
    name: '',
    type: 'MANUAL',
    ownerGroups: [],
    taxonomyLabel: '',
    requireProcessItemsHere: false,
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    'GUIDANCE_NOTES' | 'STEP_DATA' | 'AUTOMATION' | 'DUE_DATE' | 'RISK_LOG' | 'PREVIEW' | 'AGENT' | undefined
  >();

  const selectedStep = allSteps.find((step) => step.id === selectedStepId);

  useEffect(() => {
    const selectedStep = allSteps.find((step) => step.id === selectedStepId);
    if (selectedStep) {
      setEditableData({
        name: selectedStep.name,
        type: selectedStep.type,
        ownerGroups: selectedStep.ownerGroups,
        taxonomyLabel: selectedStep.taxonomyLabel,
        requireProcessItemsHere: selectedStep.requireProcessItemsHere,
      });
    }
  }, [selectedStepId]);

  const handleSave = () => {
    handleStepUpdate({
      ...selectedStep,
      ...editableData,
    } as Step);
    setIsEditing(false);
  };

  const previewCaseStep: CaseStep = {
    id: `${selectedStepId}_preview`,
    processStepId: selectedStepId || '',
    completedAt: undefined,
    decision: undefined,
    dueDate: undefined,
    caseId: '',
    companyId: '',
    createdAt: new Date(),
    stepStartedDate: new Date(),
    supportingData: {},
  }


  const previewCase: Case = {
    id: 'preview',
    process: {...process, steps: allSteps},
    caseSteps: [previewCaseStep],
    items: [],
    caseUsers: [],
    status: 'DRAFT',
    caseOwnerUserId: '',
    code: '',
    createdAt: new Date().toISOString(),
    processId: process.id,
    supportingData: {},
    companyId: '',
    completionPercentage: 0,

  }
  return (
    <Fragment>
      <div className="edit-step__header-container">
        <h3>Edit step</h3>
        <button className="icon-button" onClick={() => setIsEditing(!isEditing)}>
          <PencilIcon />
        </button>
      </div>

      {!!selectedStep && (
        <Fragment>
          <Input
            id="step-taxonomy-label"
            labelText="Taxonomy Label (optional)"
            value={editableData.taxonomyLabel || ''}
            onChange={(taxonomyLabel) => setEditableData({ ...editableData, taxonomyLabel })}
            readOnly={!isEditing}
          />

          <Input
            id="step-name"
            labelText="Name"
            value={editableData.name}
            onChange={(name) => setEditableData({ ...editableData, name })}
            readOnly={!isEditing}
          />
          <TagSelect
            availableTags={process.ownerGroups}
            selectedTags={editableData.ownerGroups}
            labelText="User roles"
            helpText="Only users in the user roles selected can complete this step"
            onChange={(ownerGroups) => setEditableData({ ...editableData, ownerGroups })}
            readOnly={!isEditing}
          />
          <Select
            id="step-type"
            labelText="Type"
            value={editableData.type}
            onChange={(type) => setEditableData({ ...editableData, type })}
            options={[
              { label: 'Step', value: 'MANUAL' },
              { label: 'Decision', value: 'DECISION' },
              { label: 'Control', value: 'CONTROL' },
            ]}
            readOnly={!isEditing}
          />
          {!!isEditing && (
            <button className="button button--small" onClick={handleSave}>
              Save
            </button>
          )}
          <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>Step Attributes</p>
          <div className="edit-step__options">
            <button className="box-button" onClick={() => setSelectedOption('GUIDANCE_NOTES')}>
              <WorkInstructionIcon />
              Add Work Instructions
            </button>
            <button className="box-button" onClick={() => setSelectedOption('STEP_DATA')}>
              <DataCollectedIcon />
              Collect data at this step
            </button>
            <button className="box-button" onClick={() => setSelectedOption('AUTOMATION')} disabled={selectedStep.type === 'CONTROL' || !!selectedStep.automation?.agent}>
              <BoltIcon />
              Set up an automation
            </button>
            <button className="box-button" onClick={() => setSelectedOption('DUE_DATE')}>
              <ClockIcon />
              Set a due date on step start
            </button>
            <button className="box-button" onClick={() => setSelectedOption('RISK_LOG')} disabled={selectedStep.type === 'CONTROL'}>
              <RiskLogIcon />
              Record risks for this step
            </button>
            <button className="box-button" onClick={() => setSelectedOption('PREVIEW')}>
              <EyeIcon />
              Preview this step
            </button>
            <button className="box-button" onClick={() => setSelectedOption('AGENT')}>
              <SparklesIcon />
              AI Agent
            </button>
          </div>
          <div className="edit-step__require-process-items-here">
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
              <ProcessitemsIcon style={{ marginTop: '-2px' }} />
              <Input
                id="require-process-items-here"
                labelText="Require process items here"
                type="checkbox"
                value={editableData.requireProcessItemsHere?.toString() || ''}
                onChange={(value) => {
                  setEditableData({ ...editableData, requireProcessItemsHere: value === 'true' });
                  handleStepUpdate({
                    ...selectedStep,
                    requireProcessItemsHere: value === 'true',
                  } as Step);
                }}
              />
            </div>

            <span className="edit-step__require-process-items-here__text">{`If checked, at least one ${process.itemName} must be added to the case before completing this step, instead of before the case is published. It is recommended that this is only checked for one step in the process.`}</span>
          </div>
          <Modal
            isOpen={!!selectedOption}
            handleClose={() => setSelectedOption(undefined)}
            modalStyles={{ }}
          >
            {selectedOption === 'GUIDANCE_NOTES' && (
              <EditGuidanceNotes
                guidanceNotes={selectedStep.guidanceNotes || ''}
                handleSave={(guidanceNotes) => {
                  handleStepUpdate({
                    ...selectedStep,
                    guidanceNotes,
                  } as Step);
                  setSelectedOption(undefined);
                }}
                entityType="CASE_STEP"
                entityId={selectedStepId!}
              />
            )}
            {selectedOption === 'STEP_DATA' && (
              <EditStepData
                supportingData={selectedStep.supportingData || []}
                onSave={(supportingData) => {
                  handleStepUpdate({
                    ...selectedStep,
                    supportingData,
                  } as Step);
                  setSelectedOption(undefined);
                }}
              />
            )}
            {selectedOption === 'AUTOMATION' && (
              <ConfigureAutomation
                allSteps={allSteps}
                process={process}
                stepId={selectedStepId!}
                stepAutomation={selectedStep.automation}
                onChange={(automation) => {
                  handleStepUpdate({
                    ...selectedStep,
                    automation,
                  } as Step);
                }}
                onSave={() => setSelectedOption(undefined)}
              />
            )}

            {selectedOption === 'DUE_DATE' && (
              <EditDueDate
                updateDueInSentence={(dueInSentence) =>
                  handleStepUpdate({ ...selectedStep, dueInSentence })
                }
                dueInSentence={selectedStep.dueInSentence}
                onSave={() => setSelectedOption(undefined)}
              />
            )}

            {selectedOption === 'RISK_LOG' && (
              <EditStepRisks
                allSteps={allSteps}
                process={process}
                stepId={selectedStepId!}
                onSave={(updatedRisks) => {
                  handleStepUpdate({
                    ...selectedStep,
                    risks: updatedRisks,
                  } as Step);
                  setSelectedOption(undefined);
                }}
              />
            )}
            {selectedOption === 'PREVIEW' && (
              <CaseStepComponent
                _case={previewCase}
                caseStepId={`${selectedStepId}_preview`}
                updateCase={() => {}}
              />
            )}
            {selectedOption === 'AGENT' && (
              <ConfigureStepAgent step={selectedStep} onSave={(updatedStep) => {
                console.log('updatedStep', updatedStep);
                handleStepUpdate(updatedStep);
                setSelectedOption(undefined);
              }} />
            )}
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditStep;
