import { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import { ItemField, ItemLookupIntegration } from '../../api/process';
import './ItemFieldForm.css';
import ItemFields from './ItemFields';
import { useDebounce } from 'use-debounce';
import { getItemLookup } from '../../api/case';
import { AppContext } from '../../App';
import { executeFormulaWithData } from '../../modules/case/case-step/utils';
import Modal from '../modal/Modal';
import AIDataExtraction from '../ai-data-extraction/AIDataExtraction';
import { BoltIcon } from '@heroicons/react/24/outline';

interface Props {
  formTitle: string;
  initialValues: any;
  fields: ItemField[];
  editOnRender?: boolean;
  onSubmit: (values: any) => void | Promise<void>;
  preventEdit?: boolean;
  caseId: string;
  itemLookupIntegration?: ItemLookupIntegration;
  itemName?: string;
}

const ItemFieldForm: React.FC<Props> = ({
  initialValues,
  editOnRender,
  fields,
  onSubmit,
  formTitle,
  preventEdit,
  caseId,
  itemLookupIntegration,
  itemName,
}) => {
  const ctx = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(editOnRender || false);
  const [showAiExtraction, setShowAiExtraction] = useState(false);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    // console.log('initialValues', initialValues);
    if (Object.keys(initialValues).length > 0) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const handleCancel = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await onSubmit(values);
    setIsEditing(false);
  };

  const lookupValue = values[itemLookupIntegration?.lookupItemFieldName || ''];
  const [debouncedLookupValue] = useDebounce(lookupValue, 500);

  const formulaItemFields = fields.filter((field) => field.type === 'formula');

  const getItemLookupData = async (value: string) => {
    const res = await getItemLookup(caseId, value);
    if (res.data) {
      console.log('setting values', res.data);
      setValues((currentValues: any) => {
        let updatedData = { ...currentValues, ...res.data };

        if (formulaItemFields.length) {
          formulaItemFields.forEach((formulaItemField) => {
            const updatedValue =
              executeFormulaWithData(formulaItemField.formula!, updatedData) || '';
            updatedData = { ...updatedData, [formulaItemField.name]: updatedValue };
          });
        }
        return updatedData;
      });
      ctx.setToast({ message: `Found ${itemName}`, type: 'success', clearAfterMs: 3000 });
    }
  };

  useEffect(() => {
    if (debouncedLookupValue && isEditing) {
      getItemLookupData(debouncedLookupValue);
    }
  }, [debouncedLookupValue]);

  return (
    <Fragment>
      <div className="item-field-form__header">
        <h3 className="item-field-form__title">{formTitle}</h3>
        {!isEditing && !preventEdit && (
          <button className="link-button" onClick={() => setIsEditing(true)}>
            Edit
          </button>
        )}
        {!!isEditing && ctx.user.company.enableDemoFeatures && (
          <button className="link-button" onClick={() => setShowAiExtraction(!showAiExtraction)}>
            <BoltIcon />
            Extract Data with AI
          </button>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <ItemFields
          fields={fields}
          values={values}
          setValues={setValues}
          isEditing={isEditing}
          entityId={caseId}
          itemLookupIntegration={itemLookupIntegration}
        />
        {!!isEditing && (
          <div className="item-field-form__button-container">
            <button className="button button--small">Save</button>
            <button
              type="button"
              onClick={handleCancel}
              className="button button--small button--secondary"
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <Modal isOpen={showAiExtraction} handleClose={() => setShowAiExtraction(false)}>
        <AIDataExtraction currentData={values} fields={fields} setCurrentData={setValues} onConfirm={() => setShowAiExtraction(false)} />
      </Modal>
    </Fragment>
  );
};

export default ItemFieldForm;
