import { useState, useRef, DragEvent, ChangeEvent } from 'react';
import './DropZone.css';

interface DropZoneProps {
  onFilesAdded: (files: File[]) => void;
  acceptedFileTypes?: string[];
  acceptedFileExtensions?: string[];
  multiple?: boolean;
  className?: string;
  children?: React.ReactNode;
}

interface FileError {
  message: string;
}

const DropZone: React.FC<DropZoneProps> = ({
  onFilesAdded,
  acceptedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  acceptedFileExtensions = ['.pdf', '.docx'],
  multiple = true,
  className = '',
  children
}) => {
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    
    const droppedFiles = Array.from(event.dataTransfer.files);
    processFiles(droppedFiles);
  };
  
  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      console.log('Files selected:', selectedFiles.map(file => ({
        name: file.name,
        type: file.type
      })));
      processFiles(selectedFiles);
    }
  };
  
  const processFiles = (newFiles: File[]) => {
    setError(null);
    
    // Ensure files have types
    const filesWithValidation = newFiles.map(file => {
      // Check if file has a type, if not try to determine by extension
      if (!file.type) {
        const fileName = file.name.toLowerCase();
        if (fileName.endsWith('.pdf')) {
          console.log(`Assigning PDF type to ${file.name}`);
          // Create a new File with the correct type
          return new File([file], file.name, { 
            type: 'application/pdf',
            lastModified: file.lastModified || Date.now()
          });
        } else if (fileName.endsWith('.docx')) {
          console.log(`Assigning DOCX type to ${file.name}`);
          return new File([file], file.name, { 
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            lastModified: file.lastModified || Date.now()
          });
        }
      }
      return file;
    });
    
    // Now check if the types match what we accept
    const invalidFiles = filesWithValidation.filter(file => {
      const fileType = file.type;
      const fileExt = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      
      return !acceptedFileTypes.includes(fileType) && 
             !acceptedFileExtensions.includes(fileExt);
    });
    
    if (invalidFiles.length > 0) {
      const fileTypeNames = acceptedFileExtensions.join(' or ').toUpperCase();
      setError(`Only ${fileTypeNames} files are allowed`);
      return;
    }
    
    // Verify files have names before passing them up
    const verifiedFiles = filesWithValidation.map(file => {
      // If the File object doesn't contain a name for some reason, try to add it
      if (!file.name) {
        const fileWithName = new File([file], `File-${Date.now()}`, { 
          type: file.type || 'application/octet-stream',
          lastModified: file.lastModified || Date.now()
        });
        console.log('Created file with name:', fileWithName);
        return fileWithName;
      }
      
      // Log the file we're about to pass to parent
      console.log('Verified file:', {
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified
      });
      
      return file;
    });
    
    onFilesAdded(verifiedFiles);
  };
  
  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`drop-zone-container ${className}`}>
      {error && <div className="drop-zone-error">{error}</div>}
      
      <div 
        className="drop-zone" 
        onDragOver={handleDragOver} 
        onDrop={handleDrop}
        onClick={openFileDialog}
      >
        {children || <p>Drop files here, or click to select files</p>}
        <input 
          type="file"
          ref={fileInputRef}
          onChange={handleFileInput}
          accept={acceptedFileExtensions.join(',')}
          style={{ display: 'none' }}
          multiple={multiple}
        />
      </div>
    </div>
  );
};

export default DropZone; 