import { FormEvent, useState } from 'react';
import * as api from '../../api';
import './FileUpload.css';
import { UploadedFile } from '../../api/file';
import Input from '../input/Input';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  entityId: string;
  entityType: UploadedFile['entityType'];
  onUploadFn: (uploadedFile: UploadedFile) => void | Promise<void>;
}

const FileUpload: React.FC<Props> = ({ entityId, entityType, onUploadFn }) => {
  const [fileName, setFileName] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string | Blob | undefined>();

  const handleFileSelect = (event: any) => {
    setSelectedFile(event.target.files[0]);
    if (!fileName && event.target.files[0]) {
      setFileName(event.target.files[0].name);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    if (selectedFile) {
      formData.append('file', selectedFile);

      // @ts-ignore
      const fileExtension = selectedFile.name.split('.').pop();
      let fileNameWithExtension = fileName;
      if (!fileName.includes(fileExtension || '')) {
        fileNameWithExtension = `${fileName}.${fileExtension}`;
      }

      formData.append('name', fileNameWithExtension);
    }

    const res = await api.files.upload(formData, entityId, entityType);
    if (res.data) {
      onUploadFn(res.data);
      setSelectedFile(undefined);
      setFileName('');
    }
  };
  return (
    <form onSubmit={handleSubmit} className="file-upload__container">
      <div className="file-upload__input-container">
        <Input
          id="file-upload-name"
          labelText=""
          value={fileName}
          onChange={setFileName}
          placeholder="Document name"
        />
        <label htmlFor="file-upload" className="file-upload__label">
          {' '}
          <input
            id="file-upload"
            name="file"
            type="file"
            onChange={handleFileSelect}
            className="file-upload__input"
          />
          <PlusCircleIcon className="file-upload__icon" />
          {!selectedFile ? 'Drag & Drop or Click to choose files' : fileName}
        </label>
      </div>

      <button
        type="button"
        onClick={handleSubmit}
        className="button file-upload__button"
        disabled={!selectedFile || !fileName}
      >
        Upload
      </button>
    </form>
  );
};

export default FileUpload;
