import { AnalyticsFilters } from '../../api/analytics';
import { Case } from '../../api/case';
import DateRangeFilter, { getDefaultDateRange } from '../../components/DateRangeFilter';
import FilterCheckboxList from './FilterCheckboxList';
import { TrashIcon } from '@heroicons/react/24/outline';
import './AnalyticsFilters.css';

interface Props {
  filters?: AnalyticsFilters;
  availableFilters?: AnalyticsFilters;
  setFilters: (filters: AnalyticsFilters) => void;
  hideExcludeDates?: boolean;
}

export const emptyFilters = (futureDates?: boolean) => {
  const defaultDateRange = getDefaultDateRange(futureDates);
  return {
    startDate: defaultDateRange.startDate,
    endDate: defaultDateRange.endDate,
    processIds: [],
    caseStatuses: [],
    processCategories: [],
  };
};

const AnalyticsFiltersComponent: React.FC<Props> = ({ filters, setFilters, availableFilters, hideExcludeDates }) => {
  return (
    <div className="analytics-filters">
      <div className="analytics-filters__header">
        <h1 className="analytics-filters__title">Analytics</h1>
        <button
          className="link-button analytics-filters__clear-button"
          style={{ marginLeft: 'auto' }}
          onClick={() => setFilters(emptyFilters())}
        >
          Clear
          <TrashIcon />
        </button>
      </div>

      <DateRangeFilter
        startDate={filters?.startDate || ''}
        endDate={filters?.endDate || ''}
        onStartDateChange={(startDate) => setFilters({ ...filters, startDate })}
        onEndDateChange={(endDate) => setFilters({ ...filters, endDate })}
        showExcludeDates={!hideExcludeDates}
        excludeDatesValue={!filters?.startDate}
        onExcludeDatesChange={(excludeDates) => {
          if (excludeDates) {
            setFilters({
              ...filters,
              startDate: undefined,
              endDate: undefined,
            });
          } else {
            const defaultDates = emptyFilters(false);
            setFilters({
              ...filters,
              startDate: defaultDates.startDate,
              endDate: defaultDates.endDate,
            });
          }
        }}
      />

      <FilterCheckboxList
        availableFilters={availableFilters?.processIds}
        selectedFilters={filters?.processIds}
        setFilters={(processIds) => setFilters({ ...filters, processIds })}
        labelText="Processes"
      />
      <FilterCheckboxList
        availableFilters={availableFilters?.processCategories}
        selectedFilters={filters?.processCategories}
        setFilters={(processCategories) => setFilters({ ...filters, processCategories })}
        labelText="Process Categories"
      />

      <FilterCheckboxList
        availableFilters={availableFilters?.caseStatuses}
        selectedFilters={filters?.caseStatuses}
        setFilters={(caseStatuses) =>
          setFilters({ ...filters, caseStatuses: caseStatuses as Case['status'][] })
        }
        labelText="Case Statuses"
      />
    </div>
  );
};

export default AnalyticsFiltersComponent;
