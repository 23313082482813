import React, { useContext } from 'react';
import { ItemField as ItemFieldType } from '../../api/process';
import Input from '../input/Input';
import FileUpload from '../file-upload/FileUpload';
import FileListItem from '../file-list/FileListItem';
import Select from '../input/Select';
import { AppContext } from '../../App';
import { validateValue } from './utils';
import { formatValue } from 'react-currency-input-field';
import { formatDate, formatDateTimeShort } from '../../utils/date-utils';
import { UploadedFile } from '../../api/file';
import { title } from 'case';

interface Props {
  field: ItemFieldType;
  value: any;
  isEditing: boolean;
  entityId: string;
  entityType: UploadedFile['entityType'];
  compact: boolean;
  onValueUpdate: (field: ItemFieldType, value: string) => void;
  style?: React.CSSProperties;
}

const ItemField: React.FC<Props> = ({
  field,
  value,
  isEditing,
  entityId,
  entityType,
  compact,
  onValueUpdate,
  style,
}) => {
  const ctx = useContext(AppContext);
  
  const label = `${field.label || title(field.name)}${field.requiredOnCreate ? '*' : ''}`;
  let displayValue = value || '';
  const originalValue = displayValue;
  
  if (!isEditing && (displayValue === 'true' || displayValue === true)) {
    displayValue = 'Yes';
  }

  if (!isEditing && (displayValue === 'false' || displayValue === false)) {
    displayValue = 'No';
  }

  // Check if value is a UUID and truncate if it is
  if (
    !isEditing &&
    typeof displayValue === 'string' &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(displayValue)
  ) {
    displayValue = displayValue.slice(0, 6) + '...';
  }

  // Check if the value is in a date format, regardless of field type
  if (!isEditing && displayValue && typeof displayValue === 'string') {
    const date = new Date(displayValue);
    if (!isNaN(date.getTime()) && displayValue.match(/^\d{4}-\d{2}-\d{2}(T|\s)/)) {
      const time = new Date(displayValue).toTimeString().slice(0, 5);
      if (time === '00:00') {
        displayValue = formatDate(displayValue, ctx.user.company.locale, ctx.user.company.timezone);
      } else {
        displayValue = formatDateTimeShort(
          displayValue,
          ctx.user.company.locale,
          ctx.user.company.timezone,
        );
      }
    }
  }

  return (
    <div className={compact ? 'item-field__compact' : ''} style={style}>
      {!isEditing && field.type !== 'file' && (
        <div className="item-field__view-only">
          <p className="item-field__view-only-label">{label}</p>
          <p className="item-field__view-only-value">{field.type === 'currency' ? formatValue({ value: String(displayValue || ''), prefix: '£' }) : displayValue}</p>
        </div>
      )}
      {!isEditing && field.type === 'file' && (
        <div className="item-field__view-only">
          <p className="item-field__view-only-label">{label}</p>
          {!!originalValue && <FileListItem fileId={originalValue} />}
          {!originalValue && <small>No file added</small>}
        </div>
      )}
      {isEditing && (
        <>
          {field.type !== 'file' && field.type !== 'select' && (
            <Input
              id={field.name}
              type={field.type}
              labelText={label}
              value={displayValue as string}
              onChange={(value: string) => onValueUpdate(field, value)}
              readOnly={!isEditing}
              invalid={!validateValue(field, displayValue)}
              minLength={field.minLength}
              maxLength={field.maxLength}
              disabled={field.type === 'formula'}
              helpText={field.type === 'formula' ? 'Calculated' : ''}
            />
          )}
          {field.type === 'select' && (
            <Select
              id={field.name}
              key={field.name}
              labelText={label}
              value={displayValue}
              onChange={(value: string) => onValueUpdate(field, value)}
              options={field.options || []}
              invalid={!validateValue(field, displayValue)}
            />
          )}
          {field.type === 'file' && (
            <div className="item-field__file">
              <p style={{  }} className="input__label">
                {label}
              </p>
              {!originalValue && !!isEditing && (
                <FileUpload
                  entityId={entityId}
                  entityType={entityType}
                  onUploadFn={(uploadedFile) => {
                    onValueUpdate(field, uploadedFile.id);
                  }}
                />
              )}
              {!originalValue && !isEditing && <small>No file added</small>}
              {!!originalValue && (
                <div>
                  <FileListItem fileId={originalValue} />
                  {isEditing && (
                    <button
                      style={{ textAlign: 'center', fontSize: '12px', float: 'right' }}
                      className="link-button"
                      onClick={() => onValueUpdate(field, '')}
                    >
                      Remove
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ItemField; 