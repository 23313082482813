import { Process, ProcessStepRisks, Step } from '.';
import { baseMethods } from '..';

export const list = async () => {
  return baseMethods.get<Process[]>({
    path: '/admin/processes',
  });
};
export type NewProcessFields = Partial<Process> &
  Required<
    Pick<
      Process,
      | 'name'
      | 'itemName'
      | 'itemDisplayField'
      | 'itemFields'
      | 'category'
      | 'ownerGroups'
      | 'taxonomyLabel'
      | 'ownerId'
    >
  >;

export const create = async (process: NewProcessFields) => {
  return baseMethods.post<Process>({
    path: '/admin/process',
    body: process,
  });
};

export const update = async (id: string, process: Partial<Process>) => {
  return baseMethods.patch<Process>({
    path: `/admin/process/${id}`,
    body: process,
  });
};

export const get = async (id: string) => {
  return baseMethods.get<Process>({
    path: `/admin/process/${id}`,
  });
};

export const listSteps = async (processId: string) => {
  return baseMethods.get<Step[]>({
    path: `/admin/process/${processId}/steps`,
  });
};

export const updateStep = async (stepId: string, updates: Partial<Step>) => {
  return baseMethods.patch<Step>({
    path: `/admin/process-step/${stepId}`,
    body: updates,
  });
};

export const bulkUpsertSteps = async (processId: string, steps: Partial<Step>[]) => {
  return baseMethods.post<Step[]>({
    path: `/admin/process/${processId}/steps`,
    body: steps,
  });
};

export const bulkUpsertStepRisks = async (
  processId: string,
  risks: Partial<ProcessStepRisks>[],
) => {
  return baseMethods.post<ProcessStepRisks[]>({
    path: `/admin/process/${processId}/step-risks`,
    body: risks,
  });
};

export type AllProcessRisk = {
  id: string;
  description: string;
  preMitigationRag: string;
  postMitigationRag: string;
  mitigationDescription: string;
  mitigationStatus: string;
  mitigationDate: string;
  processStepId: string;
  processStepName: string;
  processId: string;
  processName: string;
  processOwnerUserId: string;
  processOwnerUserEmail: string;
  processOwnerUserName: string;
};

export type AvailableProcessRiskFilters = {
  preMitigationRag: { value: string, label: string }[];
  postMitigationRag: { value: string, label: string }[];
  mitigationStatus: { value: string, label: string }[];
  processIds: { value: string, label: string }[];
  processOwnerUserIds: { value: string, label: string }[];
}

export type AllProcessRiskFilters = {
  preMitigationRag?: string[];
  postMitigationRag?: string[];
  mitigationStatus?: string[];
  mitigationDateFrom?: string;
  mitigationDateTo?: string;
  processIds?: string[];
  processOwnerUserIds?: string[];
}

export const listAllProcessRisks = async (filters: AllProcessRiskFilters) => {
  return baseMethods.get<{ availableFilters: AvailableProcessRiskFilters, risks: AllProcessRisk[] }>({
    path: '/admin/all-process-risks',
    params: filters,
  });
};
