import './StatBox.css';

interface Props {
  value: string;
  label: string;
  subLabel?: string;
  rag?: string;
}

const StatBox: React.FC<Props> = ({ value, label, subLabel, rag }) => {
  return (
    <div className='box stat-box'>
      <p className={`stat-box__value ${rag ? `stat-box__value--${rag}` : ''}`}>{value}</p>
      <span className='stat-box__label'>{label}</span>
      {subLabel && <span className='stat-box__sub-label'>{subLabel}</span>}
    </div>
  );
};

export default StatBox;
