interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const ProcessitemsIcon: React.FC<Props> = ({ className, style }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5H3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 7H9C8.448 7 8 6.552 8 6V4C8 3.448 8.448 3 9 3H20C20.552 3 21 3.448 21 4V6C21 6.552 20.552 7 20 7Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12H3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 14H9C8.448 14 8 13.552 8 13V11C8 10.448 8.448 10 9 10H20C20.552 10 21 10.448 21 11V13C21 13.552 20.552 14 20 14Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 21H9C8.448 21 8 20.552 8 20V18C8 17.448 8.448 17 9 17H20C20.552 17 21 17.448 21 18V20C21 20.552 20.552 21 20 21Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 19H4C3.448 19 3 18.552 3 18V3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ProcessitemsIcon;
