import { FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Note } from '../../../api/note';
import * as api from '../../../api';
import NoteList from './NoteList';
import Input from '../../../components/input/Input';
import './CaseNotes.css';
import { trackEvent } from '../../..';

interface Props {
  caseId: string;
}

const CaseNotes: React.FC<Props> = ({ caseId }) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState('');
  const getNotes = async () => {
    const res = await api.notes.list('CASE', caseId);
    if (res.data) {
      setNotes(res.data);
    }
  };

  const handleNewNoteSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const res = await api.notes.create('CASE', caseId, newNote);
    if (res.data) {
      trackEvent('noteCreated');
      setNotes([...notes, res.data]);
      setNewNote('');
    }
  };

  useEffect(() => {
    getNotes();
  }, [caseId]);

  useEffect(() => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
    }
  }, [notes]);

  return (
    <Fragment>
      <NoteList notes={notes} containerRef={listContainerRef} />
      <form className="case-notes__form" onSubmit={handleNewNoteSubmit}>
        <Input
          id="new-note"
          labelText="New note"
          type="textarea"
          value={newNote}
          onChange={setNewNote}
        />
        <button className="button button--secondary">Save</button>
      </form>
    </Fragment>
  );
};

export default CaseNotes;
