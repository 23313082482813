interface Props {}

const RiskLogIcon: React.FC<Props> = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 4L9.3 2.375C9.49 2.138 9.777 2 10.081 2H13.92C14.224 2 14.511 2.138 14.701 2.375L16 4V4V5C16 5.552 15.552 6 15 6H9C8.448 6 8 5.552 8 5V4V4H8Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.667 9.33301L8 11L7 10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 17.9592H14.7124"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 10H16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.667 13.333L8 15L7 14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 14H16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 4H18C19.105 4 20 4.895 20 6V20C20 21.105 19.105 22 18 22H6C4.895 22 4 21.105 4 20V6C4 4.895 4.895 4 6 4H8"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9876 15.889L19.9875 19.4726C19.9875 20.0621 19.7799 20.6327 19.4011 21.0843V21.0843C18.9246 21.6523 18.2212 21.9804 17.4797 21.9804H12.5621"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M18.0481 19.3363V17.5005"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.0471 21.3621C18.023 21.3621 18 21.3717 17.983 21.3888C17.9661 21.4059 17.9568 21.429 17.957 21.4531C17.957 21.49 17.9793 21.5233 18.0135 21.5373C18.0476 21.5513 18.0868 21.5433 18.1128 21.5171C18.1387 21.4909 18.1463 21.4515 18.1319 21.4176C18.1175 21.3836 18.084 21.3616 18.0471 21.3621"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7919 15.5196L22.8392 20.9349C23.1882 21.554 23.1825 22.3118 22.8243 22.9257C22.4661 23.5395 21.8092 23.9173 21.0985 23.9181H15.001C14.29 23.9181 13.6324 23.541 13.2736 22.9272C12.9147 22.3135 12.9085 21.5554 13.2572 20.9359L16.3045 15.5196C16.6589 14.8898 17.3255 14.5 18.0482 14.5C18.771 14.5 19.4375 14.8898 19.7919 15.5196Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RiskLogIcon;
