import { BoltIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Case, executeAutomation } from '../../../api/case';
import { CaseStep } from '../../../api/case';
import { camel } from 'case';
import ItemFields from '../../../components/item-field-form/ItemFields';
import { trackEvent } from '../../..';

interface Props {
  updateCase: (updates: Partial<Case>) => void;
  supportingData: CaseStep['supportingData'];
  setSupportingData: (supportingData: CaseStep['supportingData']) => void;
  caseId: string;
  currentCaseStepId: string;
  customAutomation: any;
  automationType: string;
  readOnly: boolean;
}

const CustomAutomation: React.FC<Props> = ({
  readOnly,
  supportingData,
  setSupportingData,
  caseId,
  currentCaseStepId,
  customAutomation,
  automationType,
  updateCase,
}) => {
  const automationValue = supportingData[camel(customAutomation.name)];
  const automationCompleted = !!automationValue;

  const handleRunAutomation = async () => {
    const res = await executeAutomation(caseId, currentCaseStepId, automationType);

    if (res.data) {
      trackEvent('automationRun')
      updateCase(res.data)
    }
  }

  return (
    <div className="letter-automation">
      <BoltIcon style={{ float: 'right', color: 'var(--yellow)' }} />
      <Fragment>
        <ItemFields
          fields={[
            {
              name: camel(customAutomation.name),
              type: 'text',
              requiredOnCreate: true,
            },
          ]}
          values={supportingData}
          setValues={setSupportingData}
          isEditing={false}
          entityId={caseId}
        />
        {!readOnly && (
          <Fragment>
            <div className="letter-automation__actions">
              <button
                className="button button--secondary button--small button--yellow"
                type="button"
                disabled={automationCompleted}
                onClick={handleRunAutomation}
              >
                <BoltIcon />
                Run {customAutomation.name}
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default CustomAutomation;
