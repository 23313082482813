interface Props {
  className?: string;
}

const WorkInstructionIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0001 16.866C9.97124 14.8362 6.91297 14.463 4.50497 15.7466C3.82168 16.1107 2.99634 15.6615 2.99634 14.8872V5.79141C2.99634 5.15514 3.29046 4.54289 3.80868 4.17373C6.29471 2.401 9.76916 2.63009 12.0001 4.86102C14.231 2.63009 17.7055 2.401 20.1915 4.17373C20.7097 4.54289 21.0038 5.15514 21.0038 5.79141V14.8872C21.0038 15.6615 20.1785 16.1117 19.4952 15.7466C17.0872 14.463 14.0289 14.8362 12.0001 16.866Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.50488 19.8842C6.91289 18.6007 9.97116 18.9739 12 21.0037C14.0288 18.9739 17.0871 18.6007 19.4951 19.8842"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0002 16.866V4.86096"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WorkInstructionIcon;
