import { baseMethods } from "..";
import { ItemField } from "../process";


export type AgentTool = {
    id: string;
    name: string;
    friendlyName: string;

    type: 'internal' | 'external';
    description: string;
    // parameters: ToolParameters;
    // allowedCompanyIds?: string[];
    /** Item fields to define any parameters that need to be hardcoded to use the tool.
     * 
     * These will be hardcoded in the process step agent configuration.
     * In future we will want to also save these in the company configuration.
    */
    hardcodedParameters?: ItemField[];
}

export const getAgentTools = async () => {
    const response = await baseMethods.get<{ externalTools: AgentTool[], internalTools: AgentTool[] }>({
        path: '/admin/agent/tools'
    })

    return response;
}