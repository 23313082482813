import { baseMethods, baseURL } from '..';
import { jwtLocalStorageKey } from '../auth';
import { ItemField } from '../process';
import * as admin from './admin';

const jwt = window.localStorage.getItem(jwtLocalStorageKey);

export const extractData = async (files: File[], itemFields: ItemField[]) => {

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  formData.append('itemFields', JSON.stringify(itemFields));

  const response = await fetch(baseURL + '/ai/extract-data', {
    method: 'POST',
    body: formData,
    headers: { 'x-jwt-auth': jwt as string }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return { data: await response.json() };
};

export type ToolUsage<OutputType = any> = {
  id: string
  toolId: string
  name: string
  friendlyName: string
  arguments: Record<string, any>
  executedAt?: Date | string
  output?: OutputType
}

export type CaseStepAiChat= {
  id: string
  createdAt: Date
  caseStepId: string
  role: 'system' | 'user' | 'assistant'
  content: string
  toolUsage?: ToolUsage[]
}

export const getCaseStepChat = async (caseStepId: string) => {
  return baseMethods.get<CaseStepAiChat[]>({
    path: `/agent/case_step_chat/${caseStepId}`
  })
}

export const sendCaseStepChat = async (caseStepId: string, message: string) => {
  return baseMethods.post<CaseStepAiChat>({
    path: `/agent/case_step_chat/${caseStepId}`,
    body: { message }
  })
}

export const takeOverCaseStepAgent = async (caseStepId: string) => {
  return baseMethods.post({
    path: `/agent/case_step_chat/${caseStepId}/take-over`
  })
}

export const resumeCaseStepAgent = async (caseStepId: string) => {
  return baseMethods.post({
    path: `/agent/case_step_chat/${caseStepId}/resume`
  })
}



export { admin }