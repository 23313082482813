import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IToast } from './components/toast/Toast';
import AppRouter from './router/AppRouter';
import * as api from './api'
import Toasts from './components/toast/Toasts';
import { User } from './api/auth';

export type ISetToastFn = (toast: IToast) => string
export type IClearToastFn = (toastId: string) => void
export interface IAppContext {
  user: User,
  setToast: ISetToastFn
  setUser: (user: User) => void;
  clearToast: IClearToastFn,
  showSearch: boolean
  setShowSearch: (showSearch: boolean) => void
  updateMade: boolean
  setUpdateMade: (updateMade: boolean) => void
}

const initialContext: IAppContext = {
  user: {
    email: '',  
    id: '',
    name: '',
    avatarColour: '',
    roles: [],
    userGroups: [],
    company: {
      id: '',
      name: '',
      backgroundColour: 'default',
      publishedProcessAllowance: 0,
      subscriptionStatus: 'ACTIVE',
      locale: 'en-GB',
      timezone: 'Europe/London',
      shortTimezone: 'GMT',
      processCategories: [],
      enableDemoFeatures: false
    }
  },
  setToast: () => uuid(),
  setUser: () => {},
  clearToast: () => {},
  showSearch: false,
  setShowSearch: () => {},
  updateMade: false,
  setUpdateMade: () => {}
}

export const AppContext = React.createContext<IAppContext>(initialContext)
interface IAppProps {
  jwt?: string
  user: User
}

const App: React.FC<IAppProps> = ({ jwt, user: incomingUser }) => {
  const [toasts, setToasts] = useState<IToast[]>([])
  const [showSearch, setShowSearch] = useState(false)
  const [user, setUser] = useState<User>(incomingUser)
  const [updateMade, setUpdateMade] = useState(false)

  const setToast = (toast: IToast) => {
    if (!toast.message && !toast.header) {
      return ''
    }

    const newToastId = uuid()
    

    setToasts((toasts) => {
      return [
        ...toasts,
        {
          id: newToastId,
          ...toast
        }
      ]
    })

    return newToastId
  }

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (updateMade) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [updateMade]);

  const clearToast = (toastId: string) => {
    setTimeout(() => {
        setToasts((toasts) => {
          return toasts.filter((toast) => {
            return toastId !== toast.id
          })
        })
    }, 300)
}

  useEffect(() => {
      api.init(setToast, clearToast, jwt)
  }, [jwt])

  const context: IAppContext = {
    setToast,
    user,
    setUser,
    clearToast,
    showSearch,
    setShowSearch,
    updateMade,
    setUpdateMade
  }

  // update body backgroubd colour
  function updateBackgroundColour() {
    const body = document.body
    if(body) {
      // body.style.backgroundColor = user.company.backgroundColour
    }
  }

  updateBackgroundColour()

  return (
    <AppContext.Provider value={context}>
      <Toasts toasts={toasts} clearToast={clearToast} />
      <AppRouter showSearch={showSearch} setShowSearch={setShowSearch}/>
    </AppContext.Provider>
    
  );
}

export default App;
