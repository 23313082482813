import { Fragment, useContext, useEffect, useState } from 'react';
import Input from '../../../../components/input/Input';
import { AppContext } from '../../../../App';
import * as api from '../../../../api';
import { useDebounce } from 'use-debounce';
import { formatDateTime } from '../../../../utils/date-utils';

interface Props {
  dueInSentence?: string;
  updateDueInSentence: (dueInSentence: string) => void;
  onSave: () => void;
  isCase?: boolean;
}

const EditDueDate: React.FC<Props> = ({ onSave, dueInSentence, updateDueInSentence, isCase }) => {
  const ctx = useContext(AppContext);
  const [debouncedDueInSentence] = useDebounce(dueInSentence, 300);
  const [dateToDisplay, setDateToDisplay] = useState<string>('');

  const calculateDueDate = async () => {
    if (!dueInSentence) return;
    const res = await api.utils.calculateDueDate(dueInSentence);
    if (res.data) {
      const formattedDate = formatDateTime(
        new Date(`${res.data.dueDate.caseDueDate}T${res.data.dueDate.caseDueTime}`),
        ctx.user.company.locale,
        ctx.user.company.timezone,
      );
      setDateToDisplay(formattedDate);
    }
  };

  useEffect(() => {
    calculateDueDate();
  }, [debouncedDueInSentence]);

  let thingName = 'step';
  if (isCase) {
    thingName = 'case';
  }

  return (
    <Fragment>
      <h3>Add a due date</h3>
      <p>
        Using a sentence, describe when this step should be completed by (from the time the step is
        started). E.g. "Friday at 5pm, in 3 days, in 6 hours, etc"
      </p>
      <Input
        id="due-date"
        labelText={`When should this ${thingName} be due?`}
        value={dueInSentence || ''}
        onChange={updateDueInSentence}
        helpText={
          dueInSentence
            ? `If the ${thingName} started now, it would be due on: ${dateToDisplay}`
            : `No due date set for this ${thingName}`
        }
      />
      <button onClick={onSave} className="button">
        Save
      </button>
    </Fragment>
  );
};

export default EditDueDate;
