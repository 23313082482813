import { camel, title } from 'case';
import { Process, Step } from '../../../api/process';
import { Fragment } from 'react';
import { getAvalableFields } from './utils';

interface Props {
  process: Process;
  currentStepId: string;
  allSteps: Step[];
  requiresCurrentStepData?: boolean;
  context?: 'letter' | 'email';
}

const AvailableFields: React.FC<Props> = ({
  process,
  currentStepId,
  allSteps,
  requiresCurrentStepData,
  context = 'letter',
}) => {
  const availableFields = getAvalableFields(process, allSteps, currentStepId, requiresCurrentStepData);
  

  return (
    <Fragment>
      <h2>How {context} templates work</h2>
      {context === 'letter' && <p>Create your template using microsoft word or google docs.</p>}
      {context === 'email' && <p>Create your template using the textboxes here.</p>}
      <p>
        Add placeholders for case data like this: <strong>{'{{source.fieldName}}'}</strong>. When
        {context === 'letter' ? ' a document' : ' an email'} is generated it will be replaced with case data.
      </p>
      <p>
        Use these fields in your {context} template to pull in data from the case, the current step, or
        the item. Fields should be surrounded by {'{{'} and {'}}'}. E.g.
      </p>
      <code>
        {'{{'}employee.addressLine1{'}}'} <br />
        {'{{'}case.incidentDate{'}}'} <br />
        {'{{'}step.actionsAgreed{'}}'} <br />
      </code>

      {context === 'email' && <p style={{ marginBottom: '16px', fontSize: '12px' }}>Note: you can use the placeholders in all parts of the email - to, cc, subject, body </p>}
      <p>Here are the placeholders you can use in your {context} template:</p>
      <h3>{title(process.itemName)}</h3>
      {!availableFields[camel(process.itemName)].length && (
        <p>No {title(process.itemName)} fields available</p>
      )}
      <ul>
        {availableFields[camel(process.itemName)].map((field) => (
          <li key={field}>
            {'{{'}
            {field}
            {'}}'}
          </li>
        ))}
      </ul>

      <h3>Case</h3>
      {!availableFields.case.length && <p>No case fields available</p>}
      <ul>
        {availableFields.case.map((field) => (
          <li key={field}>
            {'{{'}
            {field}
            {'}}'}
          </li>
        ))}
      </ul>

      <h3>Current step</h3>
      {!availableFields.step.length && (
        <Fragment>
          <p>No step fields available</p>
          <small>
            Note: this is only available if you check "Does step data need to be completed before
            generating the letter?"
          </small>
        </Fragment>
      )}
      <ul>
        {availableFields.step.map((field) => (
          <li key={field}>
            {'{{'}
            {field}
            {'}}'}
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default AvailableFields;
