import { Fragment, useContext } from 'react';
import { Case } from '../../../api/case';
import GuidanceNote from '../../../components/guidance-note/GuidanceNote';
import { lower, title } from 'case';
import './CaseStep.css';
import CaseStepCompletion from './CaseStepCompletion';
import {
  calculatePublishRequirements,
  canUserCompleteCurrentStep,
  getNextSteps,
  ownerGroupToTitleCase,
} from '../utils';
import { AppContext } from '../../../App';
import NextSteps from './NextSteps';
import DueDate from '../../../components/due-date/DueDate';
import InfoBanner from '../../../components/info-banner/InfoBanner';
import CaseStepAgentChat from '../case-step-agent/CaseStepAgentChat';

interface Props {
  _case: Case;
  caseStepId: string;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseStep: React.FC<Props> = ({ _case, caseStepId, updateCase }) => {
  const ctx = useContext(AppContext);
  let activeStep = _case.caseSteps.find((step) => step.id === caseStepId);

  const isStepView = caseStepId?.includes('new');
  const isStepPreview = caseStepId?.includes('preview');
  if (!activeStep && (isStepView || isStepPreview)) {
    activeStep = {
      id: 'new',
      processStepId: caseStepId.split('_')[0],
      completedAt: undefined,
      decision: undefined,
      dueDate: undefined,
      caseId: _case.id,
      companyId: _case.companyId,
      createdAt: new Date(),
      stepStartedDate: new Date(),
      supportingData: {},
    };
  }

  const processStep = _case.process.steps.find((step) => step.id === activeStep?.processStepId);
  const canCompleteCurrentStep =
    isStepPreview ||
    (!isStepView && activeStep
      ? canUserCompleteCurrentStep(_case, activeStep?.processStepId, ctx.user.id)
      : false);

  const nextSteps = activeStep ? getNextSteps(_case, activeStep.processStepId) : [];

  const isActiveAndUnableToComplete =
    activeStep && !canCompleteCurrentStep && !activeStep.completedAt;

  let promptForProcessItems = false;
  if (processStep?.requireProcessItemsHere) {
    const { canPublish } = calculatePublishRequirements(_case, true);
    if (!canPublish) {
      promptForProcessItems = true;
    }
  }

  return (
    <Fragment>
      {!activeStep && <p>No active step</p>}
      {!!(activeStep && processStep) && (
        <div className="case-step__container">
          <div className="case-step__main-container">
            <div className="case-step__meta">
              <p className="case-step__type">{title(processStep.type)}:</p>
              {!activeStep.completedAt && !!activeStep.dueDate && (
                <DueDate
                  date={activeStep.dueDate}
                  style={{ justifyContent: 'flex-end' }}
                  title="This task must be completed by the due date"
                />
              )}
            </div>

            <h3 className="case-step__name">
              {processStep.taxonomyLabel
                ? `${processStep.taxonomyLabel} ${processStep.name}`
                : processStep.name}
            </h3>
            {isActiveAndUnableToComplete && (
              <InfoBanner className="case-step__owners">
                <>
                  This can only be completed by a{' '}
                  {processStep.ownerGroups
                    .map((ownerGroup) => ownerGroupToTitleCase(ownerGroup))
                    .join(', ')}{' '}
                  user
                </>
              </InfoBanner>
            )}
            {!!activeStep && promptForProcessItems && (
              <InfoBanner className="case-step__owners">
                <>
                  To complete this step, you must add at least one {lower(_case.process.itemName)}
                </>
              </InfoBanner>
            )}
            {!!processStep.description && (
              <p className="case-step__description">{processStep.description}</p>
            )}
            <CaseStepCompletion
              _case={_case}
              updateCase={updateCase}
              currentCaseStep={activeStep}
              currentProcessStep={processStep}
              canCompleteCurrentStep={canCompleteCurrentStep && !activeStep.completedAt && (processStep.automation?.type !== 'AGENT' || activeStep.agentStatus === 'HELP_REQUIRED')}
              nextProcessSteps={nextSteps}
              previewMode={isStepPreview}
            />
          </div>
          <div className="case-step__next-step-container">
            {!!processStep.guidanceNotes && processStep.automation?.type !== 'AGENT' && <GuidanceNote note={processStep.guidanceNotes} />}

            {!processStep.guidanceNotes && processStep.automation?.type !== 'AGENT'  && (
              <NextSteps steps={nextSteps} currentStepId={activeStep.processStepId} />
            )}

            {processStep.automation?.type === 'AGENT' && (
              <CaseStepAgentChat caseId={_case.id} caseStepId={caseStepId} caseStepAgentStatus={activeStep?.agentStatus} stepCompleted={!!activeStep?.completedAt} />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CaseStep;
