import { Link, useLocation, useNavigate } from 'react-router-dom';
import { jwtLocalStorageKey, UserRoles } from '../../api/auth';
import {
  ArrowLeftOnRectangleIcon,
  ChartBarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShieldExclamationIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { getBrand } from '../../utils/brand';
import { trackEvent } from '../..';
import './Nav.css';
import UserChip from '../user-chip/UserChip';

interface Props {
  hideLinks?: boolean;
}

interface NavLink {
  title: string;
  to: string;
  icon: React.ComponentType;
  requiredRole?: string;
}

const Nav: React.FC<Props> = ({ hideLinks }) => {
  const ctx = useContext(AppContext);
  const path = useLocation().pathname;
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const logout = () => {
    window.localStorage.removeItem(jwtLocalStorageKey);
    window.location.reload();
  };

  const logoUrl = getBrand() === 'operonix' ? '/operonix-logo.jpg' : '/logo.png';

  const handleSearchClick = () => {
    ctx.setShowSearch(true);
    trackEvent('searchOpened');
  };

  useEffect(() => {
    if (path.includes('analytics')) {
      setExpanded(false);
    }
  }, [path]);

  // If viewport width drops below 800px, force the nav to be collapsed
  // If viewport width goes above 1400px, force the nav to be expanded
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setExpanded(false);
      } else if (window.innerWidth > 1400 && !path.includes('analytics')) {
        setExpanded(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (ctx.updateMade) {
      e.preventDefault();
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        ctx.setUpdateMade(false);
        navigate(e.currentTarget.pathname);
      }
    }
  };

  const navigationLinks: NavLink[] = [
    { title: 'Home', to: '/', icon: HomeIcon },
    { title: 'Supervisor', to: '/supervisor', icon: ClipboardIcon, requiredRole: 'supervisor' },
    { title: 'Analytics', to: '/analytics', icon: ChartBarIcon, requiredRole: 'supervisor' },
    { title: 'Risk Management', to: '/risk-management', icon: ShieldExclamationIcon, requiredRole: 'admin' },
    { title: 'Admin', to: '/admin', icon: CogIcon, requiredRole: 'admin' },
    
    // { title: 'Profile', to: '/profile', icon: UserIcon },
  ];

  return (
    <nav className={`nav ${expanded ? 'nav--expanded' : ''}`}>
      <div className="nav__company">
        <img className="nav__logo" src={logoUrl} />
        {expanded && <span className="nav__company-name">{ctx.user.company.name}</span>}
      </div>
      <button className="nav__toggle-button" onClick={() => setExpanded(!expanded)}>
        {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>
      <div className="nav__links">
        <div className="nav__links-top">
          {!hideLinks && (
            <Fragment>
              <button className="nav__link-button" onClick={handleSearchClick}>
                <div className="nav__button" title="Search">
                  <MagnifyingGlassIcon />
                </div>
                {expanded && <span className="nav__link-button-text">Search</span>}
              </button>
              {navigationLinks.map((navLink: NavLink) => {
                if (navLink.requiredRole && !ctx.user.roles.includes(navLink.requiredRole as UserRoles)) {
                  return null;
                }

                const Icon = navLink.icon;

                let isActive = false;
                if (navLink.to === '/') {
                  isActive = path === navLink.to;
                } else {
                  isActive = path.includes(navLink.to);
                }
                return (
                  <Link
                    key={navLink.to}
                    className={`nav__link-button ${isActive ? 'nav__link-button--active' : ''}`}
                    to={navLink.to}
                    title={navLink.title}
                    onClick={handleLinkClick}
                  >
                    <div className="nav__button">
                      <Icon />
                    </div>
                    {expanded && <span className="nav__link-button-text">{navLink.title}</span>}
                  </Link>
                );
              })}
            </Fragment>
          )}
        </div>
        <div className="nav__links-bottom">
          <a
            className="nav__link-button"
            title="Help"
            target="_blank"
            href="https://operonix.com/support"
          >
            <div className="nav__button">
              <QuestionMarkCircleIcon />
            </div>
            {expanded && <span className="nav__link-button-text">Help</span>}
          </a>
          <button className="nav__link-button" title="Logout" onClick={logout}>
            <div className="nav__button">
              <ArrowLeftOnRectangleIcon />
            </div>
            {expanded && <span className="nav__link-button-text">Logout</span>}
          </button>
          <div className="nav__user">
            {!!ctx.user && <UserChip user={ctx.user} compact={!expanded} />}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
