import { Fragment, useEffect, useState } from 'react';
import { Process, ProcessStepRisks, Step } from '../../../../../api/process';
import * as api from '../../../../../api';
import RiskInput from './RiskInput';
import { TrashIcon } from '@heroicons/react/24/outline';
import './EditStepRisks.css';

interface Props {
  process: Process;
  allSteps: Step[];
  stepId: string;
  onSave: (updatedRisks: ProcessStepRisks[]) => void;
}

const EditStepRisks: React.FC<Props> = ({ process, allSteps, stepId, onSave }) => {
  const [risks, setRisks] = useState<ProcessStepRisks[]>([]);

  useEffect(() => {
    const step = allSteps.find((step) => step.id === stepId);

    let risks = step?.risks;
    if (!risks?.length) {
      risks = [
        {
          description: '',
          preMitigationRag: '',
          mitigationDescription: '',
          postMitigationRag: '',
          processStepId: stepId,
          id: '',
        },
      ];
    }
    setRisks(risks);
  }, [stepId]);

  const step = allSteps.find((step) => step.id === stepId);

  const handleSave = async () => {
    const res = await api.processes.admin.bulkUpsertStepRisks(process.id, risks);
    if (res.data) {
      onSave(res.data);
    }
  };

  const risksValid = risks?.every((risk) => risk.description && risk.preMitigationRag);
  return (
    <Fragment>
      <h2>Step Risks</h2>
      <h3>
        {step?.taxonomyLabel ? `${step?.taxonomyLabel} ` : ''} {step?.name}
      </h3>
      {risks?.map((risk, index) => (
        <div className="edit-step-risks__risk" key={risk.id + String(index)}>
          <RiskInput
            key={risk.id}
            risk={risk}
            onUpdate={(updatedRisk) => {
              const updatedRisks = risks.map((risk, riskIndex) =>
                index === riskIndex ? updatedRisk : risk,
              );
              setRisks(updatedRisks);
            }}
          />
          <button
            className="icon-button"
            onClick={() => {
              const updatedRisks = risks.filter((_, riskIndex) => riskIndex !== index);
              setRisks(updatedRisks);
            }}
          >
            <TrashIcon />
          </button>
        </div>
      ))}
      <button
        className="link-button"
        onClick={() => {
          const newRisk: ProcessStepRisks = {
            id: '',
            description: '',
            preMitigationRag: '',
            mitigationDescription: '',
            postMitigationRag: '',
            processStepId: stepId,
            mitigationStatus: 'UNMITIGATED'
          };
          setRisks([...risks, newRisk]);
        }}
      >
        Add Risk
      </button>
      <div className="edit-step-risks__save-button">
        <button className="button" onClick={handleSave} disabled={!risksValid}>
          Save
        </button>
      </div>
    </Fragment>
  );
};

export default EditStepRisks;
