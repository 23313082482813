import { ProcessStepRisks } from '../../../../../api/process';

export const caculateOverallRag = (risks: ProcessStepRisks[], ignorePostMitigationRag: boolean = false): 'RED' | 'AMBER' | 'GREEN' | undefined => {
  if (!risks.length) {
    return;
  }

  let redCount = 0;
  let amberCount = 0;
  let greenCount = 0;

  risks.forEach(risk => {
    const ragStatus = ignorePostMitigationRag ? risk.preMitigationRag : risk.postMitigationRag || risk.preMitigationRag;
    
    switch (ragStatus) {
      case 'RED':
        redCount++;
        break;
      case 'AMBER':
        amberCount++;
        break;
      case 'GREEN':
        greenCount++;
        break;
    }
  });

  // Weighted calculation logic:
  // - If there's even one RED, it significantly impacts the overall status
  // - Multiple AMBER ratings can also escalate the overall status
  
  if (redCount > 0) {
    // If more than 20% of risks are red, return RED
    return (redCount / risks.length) > 0.2 ? 'RED' : 'AMBER';
  }

  if (amberCount > risks.length / 3) {
    // If more than 1/3 of risks are amber, return AMBER
    return 'AMBER';
  }

  return 'GREEN';
}