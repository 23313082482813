import { useEffect, useState, useRef } from "react";
import { CaseStepAiChat } from "../../../api/ai";
import { api } from "../../../api";
import "./CaseStepAgentChat.css";
import { useWebSocket } from "../../../hooks/useWebSocket";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { title } from "case";
import Input from "../../../components/input/Input";
import ThreeDotsLoadingSpinner from "../../../components/loading-spinner/ThreeDotsLoadingSpinner";
import ItemFields from "../../../components/item-field-form/ItemFields";
import { SparklesIcon } from "@heroicons/react/24/outline";

interface Props {
  caseId: string;
  caseStepId: string;
  caseStepAgentStatus?: "HELP_REQUIRED" | "IN_PROGRESS" | "ERROR";
  stepCompleted?: boolean;
}

const CaseStepAgentChat: React.FC<Props> = ({ caseId, caseStepId, caseStepAgentStatus, stepCompleted }) => {
  const [chatHistory, setChatHistory] = useState<CaseStepAiChat[]>([]);
  const [helpInput, setHelpInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const isPreview = caseStepId.includes("_preview");

  const getChatHistory = async () => {
    const res = await api.ai.getCaseStepChat(caseStepId);
    if (res.data) {
      setChatHistory(res.data);
    }
  };

  const handleHelpSubmit = async () => {
    if (!helpInput.trim()) return;

    try {
      await api.ai.sendCaseStepChat(caseStepId, helpInput);
      setHelpInput("");
    } catch (error) {
      console.error("Failed to send help message:", error);
    }
  };

  const { subscribe } = useWebSocket(caseId);

  useEffect(() => {
    // Subscribe to case status updates
    const unsubscribe = subscribe("case:agent:chat:updated", (data) => {
      if (data.caseStepId === caseStepId) {
        getChatHistory();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe, caseStepId]);

  useEffect(() => {
    if (!isPreview) {
      getChatHistory();
    }
  }, [caseStepId]);

  const formatTimestamp = (date: Date) => {
    return new Date(date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <div className="agent-chat">
      <div className="agent-chat__header">
        <SparklesIcon className="agent-chat__header-icon" />
        <h3>Agent</h3>
      </div>

      <div className="chat-messages">
        {chatHistory.length === 0 ? (
          <div className="chat-message chat-message--empty">
            {isPreview ? (
              <div className="chat-message__content">
                This is a preview of how the agent will interact with you. No messages yet.
              </div>
            ) : stepCompleted ? (
              <div className="chat-message__content">This step has been completed. No chat history available.</div>
            ) : caseStepAgentStatus === "HELP_REQUIRED" ? (
              <div className="chat-message__content">
                The agent is waiting for your help. Please provide information in the input below.
              </div>
            ) : (
              <div className="chat-message__content">
                The agent will start helping you with this step. Please wait...
              </div>
            )}
          </div>
        ) : (
          chatHistory.map((message) => (
            <div key={message.id} className={`chat-message chat-message--${message.role}`}>
              <div className="chat-message__content">
                <Markdown rehypePlugins={[rehypeRaw]}>{message.content}</Markdown>
              </div>
              {message.toolUsage && message.toolUsage.length > 0 && (
                <div className="chat-message__tool-usage">
                  <div className="chat-message__tool-usage-title">Tools used:</div>
                  {message.toolUsage.map((tool) => (
                    <div key={tool.id} className="chat-message__tool-item">
                      <div className="chat-message__tool-name">{title(tool.friendlyName)}</div>
                      {tool.output && (
                        <div className="chat-message__tool-output">
                          <ItemFields
                            fields={Object.keys(tool.output).map((key) => ({ name: key, type: "text" }))}
                            values={tool.output}
                            isEditing={false}
                            entityId={tool.id}
                            entityType="CASE"
                            compact={true}
                            setValues={() => {}}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="chat-message__metadata">
                <span>{message.role}</span>
                <span>{formatTimestamp(message.createdAt)}</span>
              </div>
            </div>
          ))
        )}

        <div ref={messagesEndRef} />
      </div>
      {!isPreview && !stepCompleted && caseStepAgentStatus === "IN_PROGRESS" && (
        <div className="button__group" style={{ justifyContent: "space-between" }}>
          <ThreeDotsLoadingSpinner style={{ marginLeft: "16px" }} />

          <button className="link-button" onClick={() => api.ai.takeOverCaseStepAgent(caseStepId)}>
            Take over
          </button>
        </div>
      )}

      {!isPreview && !stepCompleted && caseStepAgentStatus === "ERROR" && (
        <div className="chat-message__error">
          <div className="chat-message__error-title">Error</div>
          <div className="chat-message__error-message">The agent encountered an error. Please try again.</div>
          <button className="link-button" onClick={() => api.ai.resumeCaseStepAgent(caseStepId)}>
            Resume
          </button>
        </div>
      )}
      {!stepCompleted && caseStepAgentStatus === "HELP_REQUIRED" && (
        <div className="chat-message__help-required">
          <div className="chat-message__help-required-title">Help required</div>
          <div className="chat-message__help-required-message">The agent is asking for help</div>
          <div className="chat-message__help-required-input">
            <Input
              id="help-input"
              type="textarea"
              labelText=""
              value={helpInput}
              onChange={setHelpInput}
              placeholder="Provide information to help the agent..."
              small={true}
            />
            <div className="button__group">
              <button className="link-button" onClick={() => api.ai.resumeCaseStepAgent(caseStepId)}>
                Resume without helping
              </button>
              <button onClick={handleHelpSubmit} className="button" disabled={!helpInput.trim()}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseStepAgentChat;
