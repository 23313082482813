import styles from './Input.module.css';

interface Props {
  labelText: string;
  helpText?: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string | number }[];
  emptyMessage?: string;
  readOnly?: boolean;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
  containerClassName?: string;
  disabled?: boolean;
  invalid?: boolean;
}

const Select: React.FC<Props> = ({
  labelText,
  id,
  value,
  onChange,
  options,
  emptyMessage,
  helpText,
  readOnly,
  style,
  containerClassName,
  invalid,
  disabled,
}) => {
  return (
    <div
      className={`${styles['input__container']} ${
        invalid ? styles['input__container--invalid'] : ''
      } ${containerClassName || ''}`}
      style={style}
    >
      <label className={styles['input__label']} htmlFor={id}>
        {labelText}
      </label>
      {!readOnly && (
        <select
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={styles['input']}
          disabled={disabled}
        >
          <option value={''}>{emptyMessage || 'Please select...'}</option>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      )}
      {!!readOnly && <p className={`${styles['input']} ${styles['input--readonly']}`}>{value}</p>}
      {!!helpText && <small className={styles['input__help-text']}>{helpText}</small>}
    </div>
  );
};

export default Select;
