import { ProcessStepRisks } from '../../../../../api/process';
import Input from '../../../../../components/input/Input';
import Select from '../../../../../components/input/Select';
import './RiskInput.css';

interface Props {
  risk: ProcessStepRisks;
  onUpdate: (updatedRisk: ProcessStepRisks) => void;
}

const RiskInput: React.FC<Props> = ({ risk, onUpdate }) => {
  const ragOptions = [
    { label: 'Green', value: 'GREEN' },
    { label: 'Amber', value: 'AMBER' },
    { label: 'Red', value: 'RED' },
  ];

  const mitigationStatusOptions = [
    { label: 'Unmitigated', value: 'UNMITIGATED' },
    { label: 'Awaiting Implementation', value: 'AWAITING_IMPLEMENTATION' },
    { label: 'Implemented', value: 'IMPLEMENTED' },
  ];

  return (
    <div className="risk-input">
      <div className="risk-input__left">
        <Input
          id="risk-description"
          labelText="Risk Description"
          type="textarea"
          value={risk.description}
          onChange={(description) => onUpdate({ ...risk, description })}
        />
        <Select
          id="pre-mitigation-rag"
          labelText="Pre-Mitigation RAG"
          options={ragOptions}
          value={risk.preMitigationRag}
          onChange={(rag) => onUpdate({ ...risk, preMitigationRag: rag })}
        />
      </div>
      <div className="risk-input__middle">
        <Input
          id="mitigation-description"
          labelText="Mitigation Description (optional)"
          type="textarea"
          value={risk.mitigationDescription || ''}
          onChange={(mitigationDescription) => {
            let status = risk.mitigationStatus;
            if (mitigationDescription && status !== 'IMPLEMENTED') {
              status = 'AWAITING_IMPLEMENTATION';
            } else if (!mitigationDescription && status !== 'UNMITIGATED') {
              status = 'UNMITIGATED';
            }
            onUpdate({ ...risk, mitigationDescription, mitigationStatus: status });
          }}
        />
        <Select
          id="post-mitigation-rag"
          labelText="Post-Mitigation RAG (optional)"
          options={ragOptions}
          value={risk.postMitigationRag || ''}
          onChange={(rag) => onUpdate({ ...risk, postMitigationRag: rag })}
        />
      </div>
      <div className="risk-input__right">
        <Select
          id="mitigation-status"
          labelText="Mitigation Status (optional)"
          options={mitigationStatusOptions}
          value={risk.mitigationStatus || 'UNMITIGATED'}
          disabled={!risk.mitigationDescription}
          onChange={(status) =>
            onUpdate({
              ...risk,
              mitigationStatus: status as 'UNMITIGATED' | 'AWAITING_IMPLEMENTATION' | 'IMPLEMENTED',
            })
          }
        />
        <Input
          id="mitigation-date"
          labelText="Mitigation Date (optional)"
          type="date"
          disabled={risk.mitigationStatus !== 'IMPLEMENTED'}
          value={
            risk.mitigationDate ? new Date(risk.mitigationDate).toISOString().split('T')[0] : ''
          }
          onChange={(date) =>
            onUpdate({ ...risk, mitigationDate: date ? new Date(date) : undefined })
          }
        />
      </div>
    </div>
  );
};

export default RiskInput;
