import { kebab } from 'case'
import { unparse } from 'papaparse'

interface Props {
  data: any[]
  exportName?: string
  buttonText?: string
}

const CsvDownload: React.FC<Props> = ({ data, exportName, buttonText }) => {

  const downloadCsv = () => {
    const csv = unparse(data)
    const fileName = `${kebab(`${new Date().toISOString()}-${exportName}`)}.csv`

    // Create blob with BOM for Excel compatibility
    const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8' })
    const url = window.URL.createObjectURL(blob)
    
    const hiddenElement = document.createElement('a')
    hiddenElement.href = url
    hiddenElement.target = '_blank'
    hiddenElement.download = fileName
    hiddenElement.click()
    
    // Cleanup
    window.URL.revokeObjectURL(url)
    hiddenElement.remove()
  }

  return <button className="link-button" onClick={downloadCsv}>{buttonText || 'Download CSV'}</button>
}

export default CsvDownload