import { Fragment, useContext, useEffect, useState } from 'react';
import BuilderNav from '../../modules/admin/process-builder/builder-nav/BuilderNav';
import { useParams } from 'react-router-dom';
import { Process } from '../../api/process';
import * as api from '../../api';
import ProcessDetailsForm from '../../modules/admin/process-builder/process-details-form/ProcessDetailsForm';
import { BoltIcon, ClockIcon, PencilIcon } from '@heroicons/react/24/outline';
import './EditProcess.css';
import Modal from '../../components/modal/Modal';
import ProcessItemsConfig from '../../modules/admin/process-builder/process-items-config/ProcessItemsConfig';
import SupportingDataConfig from '../../modules/admin/process-builder/supporting-data-config/SupportingDataConfig';
import AutomateCaseCreation from '../../modules/admin/process-builder/automate-case-creation/AutomateCaseCreation';
import { AppContext } from '../../App';
import EditDueDate from '../../modules/admin/process-builder/edit-step/EditDueDate';
import AutomatedLookupIcon from '../../components/icon/AutomatedLookup';
import ConfigureAutomatedLookup from '../../modules/admin/process-builder/automate-case-creation/ConfigureAutomatedLookup';

interface Props {}

const EditProcess: React.FC<Props> = () => {
  const ctx = useContext(AppContext);
  const { id } = useParams();
  const [process, setProcess] = useState<Process | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [configMode, setConfigMode] = useState<
    'ITEMS' | 'SUPPORTING_DATA' | 'CASE_CREATION_API' | 'SET_DUE_DATE' | 'ITEM_LOOKUP' | undefined
  >();

  const getProcess = async () => {
    const res = await api.processes.admin.get(id as string);
    if (res.data) {
      setProcess(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getProcess();
    });
  }, [id]);

  const handleSave = async (formData: Partial<Process>) => {
    const res = await api.processes.admin.update(id as string, formData);
    if (!res.error) {
      setIsEditing(false);
      getProcess();
    }
  };

  const handlePublishClick = async () => {
    const published = !process?.published;
    const res = await api.processes.admin.update(id as string, { published });
    if (!res.error && process) {
      setProcess({ ...process, published });
      // temp so publish count recalcs
      window.location.reload();
    }
  };

  let publishText = 'Your process is published and new cases can be created';
  const canPublish =
    (ctx.user.company.publishedProcessCount || 0) < ctx.user.company.publishedProcessAllowance;
  if (!process?.published && canPublish) {
    publishText = 'Publish this process to allow new cases to be created';
  }

  if (!process?.published && !canPublish) {
    publishText = 'You have reached your published process allowance, please contact support.';
  }

  return (
    <Fragment>
      <BuilderNav />
      <main className="content">
        <div className="edit-process__container">
          <div className="box edit-process__box-left">
            <div className="edit-process__header">
              <h2>Process info</h2>
              <button className="icon-button" onClick={() => setIsEditing(true)}>
                <PencilIcon />
              </button>
            </div>

            {!!process && (
              <ProcessDetailsForm
                initialData={process}
                onSubmit={handleSave}
                readOnly={!isEditing}
              />
            )}
          </div>
          <div className="box edit-process__box-right">
            <h2>Process configuration</h2>
            <div
              className={`edit-process__publish-container ${
                process?.published ? '' : 'edit-process__publish-container--unpublished'
              }`}
            >
              <h3 className="edit-process__publish-header">
                {process?.published ? 'Published' : 'Not Published'}
              </h3>
              <p
                className={`edit-process__publish-text ${
                  process?.published ? '' : 'edit-process__publish-text--unpublished'
                }`}
              >
                {publishText}
              </p>
              {(process?.published || canPublish) && (
                <button onClick={handlePublishClick} className="link-button">
                  {process?.published ? 'Un-Publish' : 'Publish'}
                </button>
              )}
            </div>
            <div className="box-button__container">
              {/* <button className="box-button" onClick={() => setConfigMode('ITEMS')}>
                Configure case items
              </button>
              <button className="box-button" onClick={() => setConfigMode('SUPPORTING_DATA')}>
                Configure supporting data fields
              </button> */}

              <button className="box-button" onClick={() => setConfigMode('CASE_CREATION_API')}>
                <BoltIcon />
                Automate case creation
              </button>

              <button className="box-button" onClick={() => setConfigMode('SET_DUE_DATE')}>
                <ClockIcon />
                Set a due date on case publish
              </button>

              {ctx.user.company.enableDemoFeatures && (
                <button className="box-button" onClick={() => setConfigMode('ITEM_LOOKUP')}>
                  <BoltIcon />
                  Retrieve items from an external source
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="edit-process__container">
          <div className="box edit-process__box-left">
            {!!process && <ProcessItemsConfig process={process} onSave={handleSave} />}
          </div>
          <div className="box edit-process__box-right">
            {!!process && <SupportingDataConfig process={process} onSave={handleSave} />}
          </div>
        </div>
      </main>
      <Modal
        isOpen={!!configMode}
        handleClose={() => setConfigMode(undefined)}
        modalStyles={{ minWidth: '90vw' }}
      >
        {!!process && configMode === 'ITEMS' && (
          <ProcessItemsConfig process={process} onSave={handleSave} />
        )}
        {!!process && configMode === 'SUPPORTING_DATA' && (
          <SupportingDataConfig process={process} onSave={handleSave} />
        )}
        {!!process && configMode === 'CASE_CREATION_API' && (
          <AutomateCaseCreation process={process} onSave={handleSave} />
        )}
        {!!process && configMode === 'SET_DUE_DATE' && (
          <EditDueDate
            dueInSentence={process.dueInSentence}
            updateDueInSentence={(dueInSentence) => setProcess({ ...process, dueInSentence })}
            onSave={() => handleSave({ dueInSentence: process.dueInSentence })}
            isCase={true}
          />
        )}
        {!!process && configMode === 'ITEM_LOOKUP' && (
          <ConfigureAutomatedLookup process={process} onSave={handleSave} />
        )}
      </Modal>
    </Fragment>
  );
};

export default EditProcess;
