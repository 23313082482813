import { ArrowRightIcon, BoltIcon, CheckCircleIcon, ClockIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { Step } from '../../api/process';
import { ownerGroupToTitleCase } from '../../modules/case/utils';
import InternalControlIcon from '../icon/InternalControl';
import ProcessitemsIcon from '../icon/ProcessItems';
import DataCollectedIcon from '../icon/DataCollected';
import WorkInstructionIcon from '../icon/WorkInstructionIcon';
import { caculateOverallRag } from '../../modules/admin/process-builder/edit-step/step-risks/utils';
import { sentence } from 'case';
import './ControlStep.css';

interface Props {
  step: Step & {
    current?: boolean;
    isCompleted?: boolean;
  };
}

const ControlStep: React.FC<Props> = ({ step }) => {
  const preMitigationRag = caculateOverallRag(step.risks || [], true);
  const postMitigationRag = caculateOverallRag(step.risks || [], false);

  let hasMitigationRag = false;
  step.risks?.forEach((risk) => {
    if (risk.postMitigationRag) {
      hasMitigationRag = true;
    }
  });

  return (
    <div
      className={`control-step ${step.current ? 'control-step--selected' : ''} ${
        step.isCompleted ? 'control-step--completed' : ''
      }`}
    >
      <span className="control-step__text">
        {step.taxonomyLabel && step.taxonomyLabel + ' '}
        {step.name}
      </span>
      <ul className="control-step__owners">
        {step.ownerGroups.map((ownerGroup) => {
          return (
            <li key={ownerGroup} className="control-step__owner">
              {ownerGroupToTitleCase(ownerGroup)}
            </li>
          );
        })}
      </ul>
      <div className="control-step__icons">
        {!!step.isCompleted && (
          <CheckCircleIcon className="control-step__icon" style={{ stroke: 'var(--success)' }} />
        )}
      </div>
      <div className="control-step__attributes">
        {!!step.automation?.agent && <SparklesIcon className="control-step__icon step-node__icon--agent" />}
        {/* {step.type === 'CONTROL' && <InternalControlIcon className="control-step__icon" />} */}
        {!!step.guidanceNotes && <WorkInstructionIcon className="control-step__icon" />}
        {!!step.supportingData?.length && step.type !== 'CONTROL' && (
          <DataCollectedIcon className="control-step__icon" />
        )}
        {!!step.automation && !step.automation?.agent && <BoltIcon className="control-step__icon" />}
        {!!step.dueInSentence && <ClockIcon className="control-step__icon" />}
        {step.requireProcessItemsHere && <ProcessitemsIcon className="control-step__icon" />}
      </div>
      <div className="control-step__rag-container">
        {!!preMitigationRag && (
          <div
            className={`control-step__rag control-step__rag--${preMitigationRag.toLowerCase()}`}
            title={`Risk RAG: ${sentence(preMitigationRag)}`}
          ></div>
        )}
        {hasMitigationRag && <ArrowRightIcon className="control-step__rag-arrow" />}
        {hasMitigationRag && !!postMitigationRag && (
          <div
            className={`control-step__rag control-step__rag--${postMitigationRag.toLowerCase()}`}
            title={`Post Mitigation Risk RAG: ${sentence(postMitigationRag)}`}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ControlStep; 