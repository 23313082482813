import { Fragment, useState } from 'react';
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  linkPlugin,
  CreateLink,
  imagePlugin,
  InsertImage
} from '@mdxeditor/editor';
import './EditGuidanceNotes.css'
import * as api from '../../../../api';
import { baseURL } from '../../../../api';

interface Props {
  guidanceNotes: string;
  handleSave: (guidanceNotes: string) => void;
  entityType: string;
  entityId: string;
}

const EditGuidanceNotes: React.FC<Props> = ({ guidanceNotes, handleSave, entityId }) => {
  const [editedGuidanceNotes, setEditedGuidanceNotes] = useState<string>(guidanceNotes || '');

  const imageUploadHandler = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const res = await api.files.upload(formData, entityId, 'IMAGE', file.name);
    if (res.error) {
      throw new Error();
    }
    return `${baseURL}/images/${res.data?.id}?token=${localStorage.getItem('apiKey')}`;
  };

  const onSave = () => {
    const sanitizedNotes = editedGuidanceNotes.replace(/([?&]token=)[^&\s"]*/g, '$1{{JWT_TOKEN}}');
    handleSave(sanitizedNotes);
  }

  return (
    <Fragment>
      <h2>Add Work Instructions</h2>
      <div >
        <MDXEditor
          className="guidance-notes-editor"
          markdown={editedGuidanceNotes.replace(/{{JWT_TOKEN}}/g, localStorage.getItem('apiKey') + ')' || '')}
          contentEditableClassName='guidance-notes-editor-content'
          onChange={setEditedGuidanceNotes}
          plugins={[
            headingsPlugin(),
            listsPlugin(),
            quotePlugin(),
            thematicBreakPlugin(),
            markdownShortcutPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <BlockTypeSelect />
                  <BoldItalicUnderlineToggles />
                  <CreateLink />
                  <InsertImage />
                </>
              ),
            }),
            linkPlugin(),
            imagePlugin({
              imageUploadHandler,
            }),
          ]}
        />
      </div>
      <button className="button" onClick={onSave}>
        Save
      </button>
    </Fragment>
  );
};

export default EditGuidanceNotes;
