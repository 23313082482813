import { Fragment, useEffect, useState } from 'react';
import AnalyticsFiltersComponent, { emptyFilters } from '../modules/analytics/AnalyticsFilters';
import * as api from '../api';
import { AnalyticsFilters, AnalyticsResults } from '../api/analytics';
import './Analytics.css';
import StatBox from '../modules/analytics/StatBox';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate } from '../utils/date-utils';

interface Props {}

const AnalyticsPage: React.FC<Props> = () => {
  const [filters, setFilters] = useState<AnalyticsFilters>(emptyFilters);
  const [data, setData] = useState<AnalyticsResults>();

  const getData = async (filters: AnalyticsFilters) => {
    const res = await api.analytics.get(filters);
    if (res.data) {
      setData(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getData(filters);
    });
  }, []);

  const handleFiltersChange = (updatedFilters: AnalyticsFilters) => {
    setFilters(updatedFilters);
    setTimeout(() => {
      getData(updatedFilters);
    });
  };

  const averageCompletionData = data?.averageCompletion.map((item) => ({
    process: item.process,
    averageCompletion: Math.round(Number(item.averageCompletion) * 100),
  }));

  const processCompletionGroupData: any[] = [];
  // for 10 loops, if data exists use that, if not use a default value
  for (let i = 0; i < 10; i++) {
    const completionGroup = data?.processCompletion.find(
      (item) => item.completionPercentageGroup === String(i + 1),
    );
    if (completionGroup) {
      processCompletionGroupData.push({
        completionPercentageGroup:
          String(Number(completionGroup.completionPercentageGroup) * 10) + '%',
        caseCount: Number(completionGroup.caseCount),
      });
      continue;
    }

    processCompletionGroupData.push({
      completionPercentageGroup: String((i + 1) * 10) + '%',
      caseCount: 0,
    });
  }

  return (
    <main className="content">
      <div className="analytics-page-container">
        <div className="box">
          <AnalyticsFiltersComponent
            filters={filters}
            setFilters={handleFiltersChange}
            availableFilters={data?.availableFilters}
          />
        </div>
        <div className="analytics-container">
          {!!data && (
            <Fragment>
              <div className="analytics-stats-container">
                <StatBox value={data.caseCount[0].count.toString() || '0'} label="Total cases" />
                <StatBox
                  value={data.caseCount[0].completedCases?.toString() || '0'}
                  label="Completed cases"
                />
                <StatBox
                  value={`${Math.round(data.controlOutcomes[0].controlPassRate * 100)}%`}
                  label="Control compliance"
                  subLabel="Completed cases only"
                />
                <StatBox
                  value={`${Math.round(data.slaMet[0].slaMet * 100)}%`}
                  label="Due date compliance"
                  subLabel="Completed cases only"
                />
              </div>
              <div className="box" style={{ width: '100%', height: '400px' }}>
                <h3>Control compliance by week</h3>
                <ResponsiveContainer width="100%" height="85%">
                  <AreaChart
                    data={data.controlOutcomeOverTime.map((dataPoint) => ({
                      week: formatDate(dataPoint.week, 'en-GB', 'UTC'),
                      controlPassRate: Math.round(dataPoint.controlPassRate * 100),
                    }))}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="33%" stopColor="#3E63DD" />
                        <stop offset="66%" stopColor="#3E63DD" />
                        <stop offset="99%" stopColor="#3E63DD" />
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3E63DD" stopOpacity={0.3} />
                        <stop offset="75%" stopColor="#3E63DD" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="week"
                      label={{ value: 'Week start date', position: 'bottom' }}
                    />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value}%`} />
                    <Area
                      dataKey="controlPassRate"
                      stroke="#3E63DD"
                      strokeWidth={3}
                      dot={false}
                      type="bump"
                      fill="url(#colorPv)"
                      fillOpacity={1}
                    >
                      <LabelList
                        dataKey="controlPassRate"
                        position="top"
                        formatter={(value: string) => `${value}%`}
                      />
                    </Area>
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div className="analytics-chart-container">
                <div className="box" style={{ width: '100%', height: '400px' }}>
                  <h3>Case Completion</h3>
                  <ResponsiveContainer width="100%" height="85%">
                    <BarChart
                      data={processCompletionGroupData}
                      width={500}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="completionPercentageGroup" />
                      <YAxis
                        label={{ value: 'Case count', position: 'left', angle: -90 }}
                        tickCount={2}
                      />
                      <Tooltip />
                      <Bar dataKey="caseCount" fill="#00A2E8" maxBarSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="box" style={{ width: '100%', height: '400px' }}>
                  <h3>Average completion % by process</h3>
                  <ResponsiveContainer width="100%" height="85%">
                    <BarChart
                      data={averageCompletionData}
                      width={500}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 40,
                        bottom: 5,
                      }}
                      layout="vertical"
                    >
                      <XAxis type="number" />
                      <YAxis dataKey="process" type="category" />
                      <Tooltip formatter={(value) => `${value}%`} />
                      <Bar dataKey="averageCompletion" fill="#6E56CF" maxBarSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </main>
  );
};

export default AnalyticsPage;
