interface Props {
  className?: string;
}

const DataCollectedIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="5"
        y="3"
        width="8"
        height="4"
        rx="1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 5H14.5C15.3284 5 16 5.67157 16 6.5V19.5C16 20.3284 15.3284 21 14.5 21H3.5C2.67157 21 2 20.3284 2 19.5V6.5C2 5.67157 2.67157 5 3.5 5H5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 18.1459V5.5C22 4.67157 21.3284 4 20.5 4V4C19.6716 4 19 4.67157 19 5.5V18.1459C19 18.4564 19.0723 18.7626 19.2112 19.0403L20.0528 20.7236C20.1375 20.893 20.3106 21 20.5 21C20.6894 21 20.8625 20.893 20.9472 20.7236L21.7889 19.0403C21.9277 18.7626 22 18.4564 22 18.1459Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 16.5H13"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.50024 16.2553L6.16402 16.8517L7.50024 15.6517"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 12.5H13"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.50024 12.2553L6.16402 12.8517L7.50024 11.6517"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DataCollectedIcon;
