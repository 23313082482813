import { baseMethods } from '.'
import { AnalyticsFilters } from './analytics'
import { User } from './auth'
import { ItemField, Process, Step } from './process'

export interface CaseUser {
  id: string
  caseId: string
  userId: string
  roles: string[]
  user: {
    id: string
    email: string
    name: string
    roles: string[]
  }
}

export interface CaseStep {
  id: string
  caseId: string
  companyId: string
  createdAt: Date
  processStepId: string
  stepStartedDate: Date
  supportingData: Record<string, string | number | Date | string[]>
  dueDate?: Date
  stepCompletedDate?: Date
  decision?: string
  notes?: string
  completedAt?: Date
  completedByUserId?: string
  controlOutcome?: string
  agentStatus?: 'HELP_REQUIRED'
}

export interface Case {
  id: string
  createdAt: string
  caseOwnerUserId: string
  code: string
  status: 'DRAFT' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED'
  processId: string
  companyId: string
  nickname?: string
  caseUsers: CaseUser[]
  process: Process
  dueDate?: string
  completionPercentage: number;
  items: CaseItem[]
  caseSteps: CaseStep[]
  supportingData: Record<string, string | number | Date>
}

export interface CaseItem {
  id: string
  createdAt: string
  data: any
}

export type TaskUserRole = {
  userId: string
  roles: string
  user: User
}

export type SupervisorOustandingTask = {
  caseId: string
  code: string
  processCategory: string
  nickname: string
  completionPercentage: number
  processId: string
  processName: string
  processStepName: string
  dueDate: string // Assuming the date is returned as a string in ISO format
  caseDueDate: string
  stepStartedDate: string // Assuming the date is returned as a string in ISO format
  ownerGroups: string
  userRoles: TaskUserRole[]
}

export const create = async (processId: string) => {
  return baseMethods.post<Case>({
    path: `/process/${processId}/case`,
  })
}

export const get = async (caseId: string) => {
  return baseMethods.get<Case>({
    path: `/case/${caseId}`,
  })
}

export const createItem = async (caseId: string, item: any) => {
  return baseMethods.post<CaseItem>({
    path: `/case/${caseId}/item`,
    body: { item },
  })
}

export const upsertItems = async (caseId: string, items: any[]) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/items`,
    body: items,
  })
}

export const updateItem = async (caseId: string, itemId: string, itemData: any) => {
  return baseMethods.patch({
    path: `/case/${caseId}/item/${itemId}`,
    body: itemData,
  })
}

export const deleteItem = async (caseId: string, itemId: string) => {
  return baseMethods.del({
    path: `/case/${caseId}/item/${itemId}`,
  })
}

export const addCaseUser = async (caseId: string, userId: string, role: string) => {
  return baseMethods.post<CaseUser>({
    path: `/case/${caseId}/user/${userId}`,
    body: { role },
  })
}

export const removeCaseUser = async (caseId: string, userId: string, role: string) => {
  return baseMethods.del<{ caseUser: CaseUser; caseUserRemoved: boolean }>({
    path: `/case/${caseId}/user/${userId}`,
    body: { role },
  })
}

export const updateCaseNickname = async (caseId: string, nickname: string) => {
  return baseMethods.patch<Case>({
    path: `/case/${caseId}/nickname`,
    body: { nickname },
  })
}

export const updateSupportingInfo = async (caseId: string, supportingData: Record<string, string | number | Date>) => {
  return baseMethods.patch<Case>({
    path: `/case/${caseId}/supporting-data`,
    body: supportingData,
  })
}

export const publish = async (caseId: string) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/publish`,
  })
}

export const updateCaseStep = async (
  caseId: string,
  caseStepId: string,
  updates: Pick<CaseStep, 'supportingData' | 'decision' | 'notes' | 'controlOutcome'>
) => {
  return baseMethods.patch<CaseStep>({
    path: `/case/${caseId}/step/${caseStepId}`,
    body: updates,
  })
}

export const completeCaseStep = async (caseId: string, caseStepId: string) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/step/${caseStepId}/complete`,
  })
}

export interface OutstandingTask {
  id: string
  caseId: string
  code: string
  nickname?: string
  dueDate: Date
  name: string
  type: string
  ownerGroups: string
  userCaseRoles: string
}

export const getOutstandingTasks = async () => {
  return baseMethods.get<OutstandingTask[]>({
    path: `/assigned-tasks`,
  })
}

export interface AssignedCase {
  caseId: string
  code: string
  nickname: string
  status: string
  name: string
  [key: string]: string
}

export const getAssignedCases = async (includeCompleted?: boolean) => {
  return baseMethods.get<AssignedCase[]>({
    path: '/assigned-cases',
    params: {
      includeCompleted,
    },
  })
}

export const generateLetter = async (caseId: string, caseStepId: string) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/step/${caseStepId}/automation/letter`,
  })
}

export const generateCsv = async (caseId: string, caseStepId: string) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/step/${caseStepId}/automation/csv`,
  })
}

export const executeAutomation = async (caseId: string, caseStepId: string, automationType: string) => {
  return baseMethods.post<Case>({
    path: `/case/${caseId}/step/${caseStepId}/automation`,
    body: { automationType },
  })
}

export const getSupervisorOutstandingTasks = async (filters: AnalyticsFilters) => {
  return baseMethods.get<SupervisorOustandingTask[]>({
    path: '/supervisor/outstanding-tasks',
    params: filters,
  })
}

export const getSupervisorFilters = async () => {
  return baseMethods.get<AnalyticsFilters>({
    path: '/supervisor/filters',
  })
}

export const getItemLookup = async (caseId: string, lookupValue: string) => {
  return baseMethods.get<any>({
    path: `/case/${caseId}/item-lookup`,
    params: { lookupValue },
  })
}

export interface CalendarViewCase {
  caseId: string
  code: string
  nickname: string | null
  completionPercentage: number
  processName: string
  caseDueDate: string
  processId: string
}

export const getSupervisorCalendar = async (filters: AnalyticsFilters) => {
  return baseMethods.get<CalendarViewCase[]>({
    path: '/supervisor/outstanding-tasks/calendar',
    params: filters,
  })
}

export const getRecentCases = async () => {
  return baseMethods.get<Case[]>({
    path: '/recent-cases',
  })
}
