import { useContext, useEffect, useRef, useState } from 'react';
import { AuditLog } from '../../api/audit';
import * as api from '../../api';
import { formatDate, formatDateTimeShort } from '../../utils/date-utils';
import './AuditList.css';
import UserChip from '../user-chip/UserChip';
import { title } from 'case';
import { AppContext } from '../../App';
import ItemFields from '../item-field-form/ItemFields';
import { ClockIcon } from '@heroicons/react/24/outline';

interface Props {
  entityType: AuditLog['entityType'];
  entityId: AuditLog['entityId'];
  preloadedAuditLogs?: AuditLog[];
}

const AuditList: React.FC<Props> = ({ entityId, entityType, preloadedAuditLogs }) => {
  const ctx = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>(preloadedAuditLogs || []);

  const getAuditLogs = async () => {
    const res = await api.audit.list(entityType, entityId);
    if (res.data) {
      setAuditLogs(res.data);
    }
  };

  useEffect(() => {
    getAuditLogs();
  }, [entityId, entityType, preloadedAuditLogs]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [auditLogs]);

  return (
    <div ref={containerRef} className="audit-list__container">
      {auditLogs.map((auditLog) => {
        const dataKeys = Object.keys(auditLog.data);
        return (
          <div key={auditLog.id} className="audit-list__item">
            <div className="audit-list__item__user">
              <UserChip user={auditLog.user} small={true} />
            </div>

            <p className="audit-list__log">{auditLog.log}</p>
            <ItemFields
              fields={dataKeys.map((key) => ({ name: key, type: 'text' }))}
              values={auditLog.data}
              isEditing={false}
              entityId={auditLog.id}
              entityType="CASE"
              compact={true}
              setValues={() => {}}
            />
            <div className="audit-list__meta">
              <p className="audit-list__meta__date">
                <ClockIcon className="audit-list__meta__icon" />
                {formatDateTimeShort(
                  auditLog.createdAt,
                  ctx.user.company.locale,
                  ctx.user.company.timezone,
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AuditList;
