import { Fragment, useContext } from 'react';
import { ItemField as ItemFieldType, ItemLookupIntegration } from '../../api/process';
import { title } from 'case';
import './ItemFields.css';
import { UploadedFile } from '../../api/file';
import { validateValue } from './utils';
import { AppContext } from '../../App';
import { executeFormulaWithData } from '../../modules/case/case-step/utils';
import ItemField from './ItemField';

interface Props {
  fields: ItemFieldType[];
  isEditing: boolean;
  values: any;
  setValues: (values: any) => void;
  entityId: string;
  entityType?: UploadedFile['entityType'];
  compact?: boolean;
  itemLookupIntegration?: ItemLookupIntegration;
}

const ItemFields: React.FC<Props> = ({
  fields,
  values,
  setValues,
  isEditing,
  entityId,
  entityType = 'CASE',
  compact = false,
  itemLookupIntegration,
}) => {
  const ctx = useContext(AppContext);

  const formulaItemFields = fields.filter((field) => field.type === 'formula');

  const handleValueUpdate = (itemField: ItemFieldType, value: string) => {
    let updatedData = { ...values, [itemField.name]: value };

    if (formulaItemFields.length) {
      formulaItemFields.forEach((formulaItemField) => {
        const updatedValue = executeFormulaWithData(formulaItemField.formula!, updatedData) || '';
        updatedData = { ...updatedData, [formulaItemField.name]: updatedValue };
      });
    }

    setValues(updatedData);
  };

  return (
    <Fragment>
      {fields.map((field) => (
        <ItemField
          key={field.name}
          field={field}
          value={values[field.name] || ''}
          isEditing={isEditing}
          entityId={entityId}
          entityType={entityType}
          compact={compact}
          onValueUpdate={handleValueUpdate}
        />
      ))}
    </Fragment>
  );
};

export default ItemFields;
