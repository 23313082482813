import { FormEvent, useEffect, useState } from 'react';
import { Case, CaseStep } from '../../../api/case';
import * as api from '../../../api';
import { PreviousStep, Step } from '../../../api/process';
import { calculatePublishRequirements, isStepCompletable, stepHasSupportingDataCompleted } from '../utils';
import Select from '../../../components/input/Select';
import ItemFields from '../../../components/item-field-form/ItemFields';
import './CaseStepCompletion.css';
import Input from '../../../components/input/Input';
import LetterAutomation from '../automation/LetterAutomation';
import executeFormula from './utils';
import { trackEvent } from '../../..';
import CsvAutomation from '../automation/CsvAutomation';
import CustomAutomation from '../automation/CustomAutomation';

interface Props {
  currentCaseStep: CaseStep;
  currentProcessStep: Step;
  nextProcessSteps: Step[];
  _case: Case;
  canCompleteCurrentStep: boolean;
  updateCase: (updates: Partial<Case>) => void;
  previewMode?: boolean;
}

const CaseStepCompletion: React.FC<Props> = ({
  _case,
  currentCaseStep,
  currentProcessStep,
  canCompleteCurrentStep,
  updateCase,
  nextProcessSteps,
  previewMode,
}) => {
  const [stepDecision, setStepDecision] = useState(currentCaseStep.decision || '');
  const [supportingData, setSupportingData] = useState(currentCaseStep.supportingData);
  const [stepNotes, setStepNotes] = useState(currentCaseStep.notes || '');
  const [controlOutcome, setControlOutcome] = useState(currentCaseStep.controlOutcome || '');

  const nextProcessStepConnections: PreviousStep[] = [];
  nextProcessSteps.forEach((nextProcessStep) => {
    nextProcessStep.previousSteps?.forEach((previousStepConnection) => {
      if (previousStepConnection.id === currentProcessStep.id) {
        nextProcessStepConnections.push(previousStepConnection);
      }
    });
  });

  const decisionOptions = nextProcessStepConnections.map((step) => ({
    label: step.label!,
    value: step.label!,
  }));

  const controlOutcomeOptions = [
    { label: 'Pass', value: 'PASS' },
    { label: 'Fail', value: 'FAIL' },
  ];

  useEffect(() => {
    if (
      currentProcessStep.autoDecisionFormula &&
      !currentCaseStep.completedAt &&
      !currentCaseStep.decision
    ) {
      const decision = executeFormula(
        currentProcessStep.autoDecisionFormula,
        _case,
        currentCaseStep.id,
      );
      const decisionOption = decisionOptions.find((option) => {
        const stringToMatch = decision ? 'yes' : 'no';
        return option.value.toLowerCase().includes(stringToMatch);
      });

      if (decisionOption) {
        setStepDecision(decisionOption.value);
        api.cases.updateCaseStep(_case.id, currentCaseStep.id, {
          supportingData,
          decision: decisionOption.value,
          notes: stepNotes,
        });
      }
    } else {
      setStepDecision(currentCaseStep.decision || '');
    }

    setSupportingData(currentCaseStep.supportingData);
    setStepNotes(currentCaseStep.notes || '');
    setControlOutcome(currentCaseStep.controlOutcome || '');
    setStepDecision(currentCaseStep.decision || '');
  }, [currentCaseStep, currentProcessStep]);

  const handleCancel = () => {
    setStepDecision(currentCaseStep.decision || '');
    setSupportingData(currentCaseStep.supportingData);
    setStepNotes(currentCaseStep.notes || '');
    setControlOutcome(currentCaseStep.controlOutcome || '');
  };

  const handleSaveSubmit = async (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (previewMode) {
      return;
    }
    if (!canCompleteCurrentStep || _case.id === 'fake-case-id') {
      return;
    }

    const res = await api.cases.updateCaseStep(_case.id, currentCaseStep.id, {
      supportingData,
      decision: stepDecision,
      notes: stepNotes,
      controlOutcome,
    });

    if (res.data) {
      const updatedCase: Case = {
        ..._case,
        caseSteps: _case.caseSteps.map((step) => {
          if (step.id === currentCaseStep.id) {
            return res.data!;
          }
          return step;
        }),
      };

      updateCase(updatedCase);
    }
  };

  let { isCompletable: stepCompletable, errors } = isStepCompletable(
    {
      ...currentCaseStep,
      supportingData,
      decision: stepDecision,
      notes: stepNotes,
      controlOutcome,
    },
    currentProcessStep,
  );

  let promptForProcessItems = false;
  if (currentProcessStep.requireProcessItemsHere) {
    const { canPublish, publishRequirements } = calculatePublishRequirements(_case, true);
    if (!canPublish) {
      errors.push(publishRequirements.join(', '));
      stepCompletable = false;
      promptForProcessItems = true;
    }
  }

  const hasUnsavedChanges =
    stepDecision !== (currentCaseStep.decision || '') ||
    stepNotes !== (currentCaseStep.notes || '') ||
    controlOutcome !== (currentCaseStep.controlOutcome || '') ||
    JSON.stringify(supportingData) !== JSON.stringify(currentCaseStep.supportingData);

  const handleCompleteStepClick = async () => {
    if (!stepCompletable || _case.id === 'fake-case-id') {
      return;
    }

    if (previewMode) {
      return;
    }

    if (hasUnsavedChanges) {
      await handleSaveSubmit();
    }

    const res = await api.cases.completeCaseStep(_case.id, currentCaseStep.id);
    if (res.data) {
      trackEvent('stepCompleted');
      setStepNotes('');
      setStepDecision('');
      setControlOutcome('');
      setSupportingData({});
      updateCase(res.data);
    }
  };
  const letterAutomation = currentProcessStep.automation?.letter;
  const csvAutomation = currentProcessStep.automation?.csvDownload;
  const emailAutomation = currentProcessStep.automation?.email;

  return (
    <div className="case-step-completion__container">
      <form className="case-step-completion" onSubmit={handleSaveSubmit}>
        {currentProcessStep.type === 'DECISION' && (
          <Select
            id={`decision-${currentCaseStep.id}`}
            labelText="Decision*"
            onChange={setStepDecision}
            value={stepDecision}
            options={decisionOptions}
            readOnly={!canCompleteCurrentStep || !!currentProcessStep.autoDecisionFormula}
            helpText={
              currentProcessStep.autoDecisionFormula ? 'This decision is automated' : undefined
            }
          />
        )}
        {!!currentProcessStep.supportingData && (
          <ItemFields
            fields={currentProcessStep.supportingData}
            values={supportingData}
            setValues={setSupportingData}
            isEditing={canCompleteCurrentStep}
            entityId={_case.id}
          />
        )}
        {currentProcessStep.type === 'CONTROL' && (
          <Select
            id={`control-outcome-${currentCaseStep.id}`}
            labelText="Control Outcome*"
            onChange={setControlOutcome}
            value={controlOutcome}
            options={controlOutcomeOptions}
            readOnly={!canCompleteCurrentStep || !!currentProcessStep.autoDecisionFormula}
          />
        )}
        <Input
          id={`notes-${currentCaseStep.id}`}
          labelText="Notes specific to this step"
          type="textarea"
          value={stepNotes}
          onChange={setStepNotes}
          readOnly={!canCompleteCurrentStep}
        />
        {!!letterAutomation && (
          <LetterAutomation
            caseId={_case.id}
            currentCaseStepId={currentCaseStep.id}
            letterAutomation={letterAutomation}
            setSupportingData={setSupportingData}
            stepHasSupportingDataCompleted={stepHasSupportingDataCompleted(
              currentCaseStep,
              currentProcessStep,
            )}
            supportingData={supportingData}
            updateCase={updateCase}
            readOnly={!canCompleteCurrentStep}
          />
        )}
        {!!csvAutomation && (
          <CsvAutomation
            caseId={_case.id}
            currentCaseStepId={currentCaseStep.id}
            csvAutomation={csvAutomation}
            setSupportingData={setSupportingData}
            supportingData={supportingData}
            updateCase={updateCase}
            readOnly={!canCompleteCurrentStep}
          />
        )}
        {!!emailAutomation && (
          <CustomAutomation
            automationType="email"
            caseId={_case.id}
            currentCaseStepId={currentCaseStep.id}
            customAutomation={emailAutomation}
            setSupportingData={setSupportingData}
            supportingData={supportingData}
            updateCase={updateCase}
            readOnly={!canCompleteCurrentStep}
          />
        )}
        {!!canCompleteCurrentStep && (
          <div className="case-step-completion__form-actions">
            <button
              className="button button--secondary"
              disabled={!hasUnsavedChanges}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="button" disabled={!hasUnsavedChanges}>
              Save
            </button>
          </div>
        )}
        {!!errors.length && (
          <small className="case-step-completion__error">
            To complete step: {errors.join(', ')}.
          </small>
        )}
      </form>

      <button
        onClick={handleCompleteStepClick}
        disabled={!(!!canCompleteCurrentStep && !!stepCompletable)}
        className="button case-step-complete-button"
        type="button"
      >
        {hasUnsavedChanges ? 'Save & Complete Step' : 'Complete Step'}
      </button>
    </div>
  );
};

export default CaseStepCompletion;
