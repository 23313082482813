import { TrashIcon } from '@heroicons/react/24/outline';
import { DataMapping } from '../../../../api/integration/admin';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import './DataMappingInput.css';
import { ItemField } from '../../../../api/process';

interface Props {
  dataMapping: DataMapping;
  onChange: (dataMapping: DataMapping) => void;
  onDelete?: (dataMapping: DataMapping) => void;
  excludeOutputBaseFields?: ('supportingData' | 'items')[];
  availableFields?: {
    supportingData?: ItemField[];
    items?: ItemField[];
  };
  availableIncomingFields?: string[];
}

const DataMappingInput: React.FC<Props> = ({
  dataMapping,
  onChange,
  onDelete,
  excludeOutputBaseFields,
  availableFields,
  availableIncomingFields,
}) => {
  let outputType = '';
  let outputField = '';

  const currentOutputParts = dataMapping.outputKey.split('.');
  if (currentOutputParts[0]) {
    outputType = currentOutputParts[0];
  }

  if (currentOutputParts[1]) {
    outputField = currentOutputParts[1];
  }

  const outputBaseFieldOptions = [
    { label: 'supportingData', value: 'supportingData' },
    { label: 'items', value: 'items' },
  ].filter(
    (option) =>
      !excludeOutputBaseFields?.includes(option.value as 'supportingData' | 'items'),
  );

  if (outputBaseFieldOptions.length === 1 && !outputType) {
    onChange({ ...dataMapping, outputKey: `${outputBaseFieldOptions[0].value}.${outputField}` });
  }

  const outputFieldOptions = availableFields?.[outputType as 'supportingData' | 'items']?.map(
    (field) => ({
      label: field.label || field.name,
      value: field.name,
    }),
  );

  return (
    <div className="data-mapping-input__container">
        {!availableIncomingFields?.length && (
          <Input
        id={`data-mapping-incoming-input-${dataMapping.id}`}
        labelText="Incoming Field"
        value={dataMapping.inputKey}
        onChange={(inputKey) => onChange({ ...dataMapping, inputKey })}
        placeholder="customer.firstName"
        />
      )}
      {availableIncomingFields?.length && (
        <Select
          id={`data-mapping-incoming-input-${dataMapping.id}`}
          labelText="Incoming Field"
          value={dataMapping.inputKey}
          options={availableIncomingFields.map((field) => ({
            label: field,
            value: field,
          }))}
          onChange={(inputKey) => onChange({ ...dataMapping, inputKey })}
        />
      )}
      <span>to</span>
      <div className="data-mapping-input__field-container">
        <Select
          id={`data-mapping-outgoing-type-input-${dataMapping.id}`}
          labelText="Output Base Field"
          value={outputType}
          onChange={(outputType) =>
            onChange({ ...dataMapping, outputKey: `${outputType}.${outputField}` })
          }
          options={outputBaseFieldOptions}
        />
        {!outputFieldOptions?.length && (
          <Input
            id={`data-mapping-outgoing-field-input-${dataMapping.id}`}
            labelText="Output Field"
            value={outputField}
            onChange={(outputField) =>
              onChange({ ...dataMapping, outputKey: `${outputType}.${outputField}` })
            }
          />
        )}

        {outputFieldOptions?.length && (
          <Select
            id={`data-mapping-outgoing-field-input-${dataMapping.id}`}
            labelText="Output Field"
            value={outputField}
            options={outputFieldOptions}
            onChange={(outputField) =>
              onChange({ ...dataMapping, outputKey: `${outputType}.${outputField}` })
            }
          />
        )}
      </div>
      {!!onDelete && (
        <button className="icon-button" onClick={() => onDelete(dataMapping)}>
          <TrashIcon />
        </button>
      )}
    </div>
  );
};

export default DataMappingInput;
