import { useState, useEffect, Fragment } from 'react';
import {
  AllProcessRisk,
  AllProcessRiskFilters,
  AvailableProcessRiskFilters,
  listAllProcessRisks,
} from '../api/process/admin';
import TableComponent, { TableHeader } from '../components/table/Table';
import { sentence, upper } from 'case';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import './RiskManagementPage.css';
import { formatDate } from '../utils/date-utils';
import UserChip from '../components/user-chip/UserChip';
import DateRangeFilter from '../components/DateRangeFilter';
import FilterCheckboxList from '../modules/analytics/FilterCheckboxList';
import CsvDownload from '../components/csv-download/CsvDownload';

interface Props {}

const RiskManagementPage: React.FC<Props> = () => {
  const [risks, setRisks] = useState<AllProcessRisk[]>([]);
  const [availableFilters, setAvailableFilters] = useState<AvailableProcessRiskFilters>();
  const [selectedFilters, setSelectedFilters] = useState<AllProcessRiskFilters>({});

  useEffect(() => {
    const fetchRisks = async () => {
      const res = await listAllProcessRisks(selectedFilters);
      if (res.data) {
        setRisks(res.data.risks);
        setAvailableFilters(res.data.availableFilters);
      }
    };
    setTimeout(() => {
      fetchRisks();
    });
  }, [selectedFilters]);

  const headers: TableHeader<AllProcessRisk>[] = [
    // 'id',
    (risk) => {
      return {
        header: 'Process Step',
        element: (
          <div className="risk-management-page__process-step-container">
            <div className="risk-management-page__process-name">{risk?.processName}</div>
            <div className="risk-management-page__process-step-name">{risk?.processStepName}</div>
          </div>
        ),
      };
    },
    (risk) => {
      return {
        header: 'RAG',
        element: (
          <div className="risk-management-page__rag-container">
            {!!risk?.preMitigationRag && (
              <div
                className={`step-node__rag step-node__rag--${risk.preMitigationRag.toLowerCase()}`}
                title={`Risk RAG: ${sentence(risk.preMitigationRag)}`}
              ></div>
            )}
            {risk?.postMitigationRag && <ArrowRightIcon className="step-node__rag-arrow" />}
            {risk?.postMitigationRag && (
              <div
                className={`step-node__rag step-node__rag--${risk.postMitigationRag.toLowerCase()}`}
                title={`Post Mitigation Risk RAG: ${sentence(risk.postMitigationRag)}`}
              ></div>
            )}
          </div>
        ),
      };
    },
    'description',
    (risk) => {
      return {
        header: 'Status',
        element: (
          <div className="risk-management-page__mitigation-container">
            <div
              className={`risk-management-page__mitigation-status risk-management-page__mitigation-status--${risk?.mitigationStatus.toLowerCase()}`}
            >
              {upper(sentence(risk?.mitigationStatus || ''))}
            </div>
            {!!risk?.mitigationDate && (
              <div className="risk-management-page__mitigation-date">
                (on {formatDate(risk?.mitigationDate)})
              </div>
            )}
          </div>
        ),
      };
    },
    'mitigationDescription',
    (risk) => {
      return {
        header: 'Owner',
        element: (
          <Fragment>
            {!!risk?.processOwnerUserName && (
              <UserChip
                user={{
                  id: risk?.processOwnerUserId || '',
                  name: risk?.processOwnerUserName || '',
                  email: risk?.processOwnerUserEmail || '',
                  avatarColour: 'blue',
                }}
              />
            )}
          </Fragment>
        ),
      };
    },
  ];

  return (
    <main className="content">
      <div className="box" style={{ marginRight: '16px' }}>
        <h1>Risk Management</h1>
      </div>
      <div className="risk-management__content">
        <div className="risk-management__filters">
          <div className="box">
            <h2>Filters</h2>
            <div className="risk-management__filters-section">
              <FilterCheckboxList
                labelText="Mitigation Status"
                availableFilters={availableFilters?.mitigationStatus?.map(
                  (status) => `${status.value}__${status.label}`,
                )}
                selectedFilters={selectedFilters?.mitigationStatus}
                setFilters={(mitigationStatus) =>
                  setSelectedFilters({ ...selectedFilters, mitigationStatus })
                }
              />
            </div>
            <div className="risk-management__filters-section">
              <FilterCheckboxList
                labelText="Pre-mitigation RAG"
                availableFilters={availableFilters?.preMitigationRag?.map(
                  (rag) => `${rag.value}__${rag.label}`,
                )}
                selectedFilters={selectedFilters?.preMitigationRag}
                setFilters={(preMitigationRag) =>
                  setSelectedFilters({ ...selectedFilters, preMitigationRag })
                }
              />
            </div>
            <div className="risk-management__filters-section">
              <FilterCheckboxList
                labelText="Post-mitigation RAG"
                availableFilters={availableFilters?.postMitigationRag?.map(
                  (rag) => `${rag.value}__${rag.label}`,
                )}
                selectedFilters={selectedFilters?.postMitigationRag}
                setFilters={(postMitigationRag) =>
                  setSelectedFilters({ ...selectedFilters, postMitigationRag })
                }
              />
            </div>
            <div className="risk-management__filters-section">
              <FilterCheckboxList
                labelText="Process"
                availableFilters={availableFilters?.processIds?.map(
                  (process) => `${process.value}__${process.label}`,
                )}
                selectedFilters={selectedFilters?.processIds}
                setFilters={(processIds) => setSelectedFilters({ ...selectedFilters, processIds })}
              />
            </div>
            <div className="risk-management__filters-section">
              <FilterCheckboxList
                labelText="Owner"
                availableFilters={availableFilters?.processOwnerUserIds?.map(
                  (user) => `${user.value}__${user.label}`,
                )}
                selectedFilters={selectedFilters?.processOwnerUserIds}
                setFilters={(processOwnerUserIds) =>
                  setSelectedFilters({ ...selectedFilters, processOwnerUserIds })
                }
              />
            </div>
            {/* <DateRangeFilter
              label="Implementation"
              startDate={selectedFilters?.mitigationDateFrom || ''}
              endDate={selectedFilters?.mitigationDateTo || ''}
              onStartDateChange={(startDate) =>
                setSelectedFilters({ ...selectedFilters, mitigationDateFrom: startDate })
              }
              onEndDateChange={(endDate) =>
                setSelectedFilters({ ...selectedFilters, mitigationDateTo: endDate })
              }
            /> */}
          </div>
        </div>
        <div className="box risk-management__table">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CsvDownload data={risks} exportName="process-risks" />
          </div>

          <TableComponent data={risks} headers={headers} disableTruncation search />
        </div>
      </div>
    </main>
  );
};

export default RiskManagementPage;
