import { useEffect, useCallback, useState, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { jwtLocalStorageKey } from '../api/auth';

// Define the types for our events
type EventsMap = {
  'case:status:updated': {
    caseId: string;
    status: string;
    userId: string;
    companyId: string;
    timestamp: string;
  };
  'case:agent:chat:updated': {
    caseStepId: string;
  };
  'case.step.updated': {
    caseStepId: string;
  }
  // Add more event types here as needed
};

type EventKey = keyof EventsMap;
type EventCallback<T extends EventKey> = (data: EventsMap[T]) => void;

// Socket URL configuration
const socketUrl = process.env.REACT_APP_API_URL || 'http://localhost:9000';

// Create socket instance
const socket = io(socketUrl, {
  auth: {
    token: localStorage.getItem(jwtLocalStorageKey)
  },
  transports: ['websocket', 'polling'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 60000, // Time to wait for a pong before considering the connection dead
  autoConnect: true,
});

export function useWebSocket(caseId?: string) {
  const [isConnected, setIsConnected] = useState(false);
  const [connectionHealth, setConnectionHealth] = useState<'healthy' | 'degraded' | 'unhealthy'>('healthy');
  const [lastPongTime, setLastPongTime] = useState<number | null>(null);
  const missedPongsRef = useRef(0);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const checkConnectionHealth = useCallback(() => {
    if (missedPongsRef.current >= 3) {
      setConnectionHealth('unhealthy');
      console.warn('Connection appears to be dead, attempting reconnection');
      socket.disconnect();
      socket.connect();
      missedPongsRef.current = 0;
    } else if (missedPongsRef.current >= 1) {
      setConnectionHealth('degraded');
    } else {
      setConnectionHealth('healthy');
    }
  }, []);

  useEffect(() => {
    if (!caseId) {
      console.log('No caseId provided, skipping socket connection');
      return;
    }

    const handleConnect = () => {
      console.log('Socket connected with id:', socket.id);
      setIsConnected(true);
      setConnectionHealth('healthy');
      missedPongsRef.current = 0;
      
      // Force a small delay before joining to ensure connection is stable
      setTimeout(() => {
        console.log('Joining case room:', caseId);
        socket.emit('case:join', caseId);
      }, 100);
    };

    // If already connected, join immediately
    if (socket.connected) {
      handleConnect();
    }

    socket.on('connect', handleConnect);

    socket.on('connect_error', (error: Error) => {
      console.error('Socket connection error:', error);
      setIsConnected(false);
      setConnectionHealth('unhealthy');
    });

    // Monitor heartbeat
    socket.on('ping', () => {
      console.log('Received ping from server');
    });

    socket.on('pong', (latency: number) => {
      console.log('Received pong from server, latency:', latency, 'ms');
      setLastPongTime(Date.now());
      missedPongsRef.current = 0;
      checkConnectionHealth();
    });

    // Set up heartbeat monitoring interval
    heartbeatIntervalRef.current = setInterval(() => {
      missedPongsRef.current++;
      checkConnectionHealth();
    }, 60000); // Use the same timeout value as above

    return () => {
      console.log('Cleaning up socket listeners');
      socket.off('connect', handleConnect);
      socket.off('connect_error');
      socket.off('ping');
      socket.off('pong');
      
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      
      // Leave the case room on cleanup
      if (caseId) {
        socket.emit('case:leave', caseId);
      }
    };
  }, [caseId, checkConnectionHealth]);

  const subscribe = useCallback(<T extends EventKey>(
    event: T,
    callback: EventCallback<T>
  ) => {
    socket.on(event, callback as any);
    return () => {
      socket.off(event, callback as any);
    };
  }, []);

  const emit = useCallback(<T extends EventKey>(
    event: T,
    data: EventsMap[T]
  ) => {
    socket.emit(event, data);
  }, []);

  return {
    isConnected,
    connectionHealth,
    lastPongTime,
    subscribe,
    emit
  };
}

// Example usage:
/*
function CaseComponent({ caseId }: { caseId: string }) {
  const { isConnected, subscribe, emit } = useWebSocket(caseId);

  useEffect(() => {
    // Subscribe to case status updates
    const unsubscribe = subscribe('case:status:updated', (data) => {
      console.log('Case status updated:', data);
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  const handleStatusUpdate = (newStatus: string) => {
    emit('case:status:update', {
      caseId,
      status: newStatus
    });
  };

  return (
    <div>
      <div>Connection status: {isConnected ? 'Connected' : 'Disconnected'}</div>
    </div>
  );
}
*/ 