import { format } from 'date-fns-tz';
import { endOfMonth, startOfMonth } from 'date-fns';
import Input from './input/Input';
import './DateRangeFilter.css';

interface DateRangeFilterProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  showExcludeDates?: boolean;
  onExcludeDatesChange?: (excludeDates: boolean) => void;
  excludeDatesValue?: boolean;
  label?: string;
}

// Get the current date in UTC
const currentDate = new Date();

// Convert UTC dates to the target timezone (in this case, keeping it UTC)
const threeMonthsAgo = new Date(currentDate);
threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
const startOfThreeMonthsAgo = startOfMonth(threeMonthsAgo);
const lastMonth = new Date(currentDate);
lastMonth.setMonth(lastMonth.getMonth() - 1);
const endOfLastMonth = endOfMonth(lastMonth);

export const getDefaultDateRange = (futureDates?: boolean) => {
  const startOfThreeMonthsAgoUTCString = format(startOfThreeMonthsAgo, 'yyyy-MM-dd', { timeZone: 'UTC' });
  const endOfLastMonthUTCString = format(endOfLastMonth, 'yyyy-MM-dd', { timeZone: 'UTC' });
  const startOfThisMonthUTCString = format(startOfMonth(currentDate), 'yyyy-MM-dd', { timeZone: 'UTC' });
  const endOfThisMonthUTCString = format(endOfMonth(currentDate), 'yyyy-MM-dd', { timeZone: 'UTC' });

  return {
    startDate: futureDates ? startOfThisMonthUTCString : startOfThreeMonthsAgoUTCString,
    endDate: futureDates ? endOfThisMonthUTCString : endOfLastMonthUTCString,
  };
};

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  showExcludeDates = false,
  onExcludeDatesChange,
  excludeDatesValue,
  label,
}) => {
  return (
    <div>
      <div className="date-range-filter__inputs">
        <Input
          id="start-date"
          labelText={`${label ? label + ' ' : ''}Date from`}
          value={startDate || ''}
          onChange={onStartDateChange}
          type="date"
          small={true}
        />
        <Input
          id="end-date"
          labelText={`${label ? label + ' ' : ''}Date to`}
          value={endDate || ''}
          onChange={onEndDateChange}
          type="date"
          small={true}
        />
      </div>

      {showExcludeDates && onExcludeDatesChange && (
        <Input
          id="exclude-dates"
          labelText="Cases without due dates"
          type="checkbox"
          value={excludeDatesValue?.toString() || ''}
          onChange={(value) => onExcludeDatesChange(value === 'true')}
        />
      )}
    </div>
  );
};

export default DateRangeFilter; 