import { Fragment, useEffect, useState } from 'react';
import { UploadedFile } from '../../api/file';
import * as api from '../../api';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import DocumentIcon from '../icon/Document';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';

interface Props {
  fileId?: string;
  preloadedFile?: UploadedFile;
  refreshAuditLogs?: () => void;
}

const FileListItem: React.FC<Props> = ({ fileId, preloadedFile, refreshAuditLogs }) => {
  const [file, setFile] = useState<UploadedFile | undefined>(preloadedFile);

  const getFile = async () => {
    if (fileId) {
      const res = await api.files.get(fileId);
      if (res.data) {
        setFile(res.data);
      }
    }
  };

  useEffect(() => {
    getFile();
  }, [fileId]);

  const downloadFile = async (selectedFile: UploadedFile) => {
    const res = await api.files.download(
      selectedFile.entityId,
      selectedFile.entityType,
      selectedFile.id as string,
    );
    if (res.data) {
      const downloadUrl = window.URL.createObjectURL(res.data as any);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', selectedFile.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (refreshAuditLogs) {
        refreshAuditLogs();
      }
    }
  };

  return (
    <div className="file-list__item">
      {!!file && (
        <>
          <DocumentIcon />
          <p className="file-list__item-title">{file.name}</p>
          <button style={{ marginLeft: 'auto'}} className="icon-button icon-button--grey" onClick={() => downloadFile(file)}>
            <CloudArrowDownIcon />
          </button>
        </>
      )}
      {!file && <LoadingSpinner />}
    </div>
  );
};

export default FileListItem;
