import { FormEvent, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Case } from '../api/case';
import * as api from '../api';
import CaseUsers from '../modules/case/case-users/CaseUsers';
import './ViewCasePage.css';
import ProcessMap from '../modules/process-map/ProcessMap';
import SupportingInfo from '../modules/case/supporting-info/SupportingInfo';
import CaseStatus from '../modules/case/case-status/CaseStatus';
import { calculatePublishRequirements, getActiveCaseStep } from '../modules/case/utils';
import CaseStep from '../modules/case/case-step/CaseStep';
import CaseDocuments from '../modules/case/case-documents/CaseDocuments';
import AuditList from '../components/audit-list/AuditList';
import CaseStepHistory from '../modules/case/case-step-history/CaseStepHistory';
import { AuditLog } from '../api/audit';
import CaseNotes from '../modules/case/case-notes/CaseNotes';
import CaseData from '../modules/case/case-data/CaseData';
import Input from '../components/input/Input';
import { CheckCircleIcon, ExclamationTriangleIcon, PencilIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import CaseStatusChip from '../modules/case/case-status/CaseStatusChip';
import { AppContext } from '../App';
import InfoBanner from '../components/info-banner/InfoBanner';
import { lower, title } from 'case';
import CaseItemsV2 from '../modules/case/case-items-v2/CaseItemsV2';
import Modal from '../components/modal/Modal';
import { useWebSocket } from '../hooks/useWebSocket';

interface Props {}

const ViewCasePage: React.FC<Props> = () => {
  const bottomPanelRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [_case, setCase] = useState<Case | undefined>();
  const [editingNickname, setEditingNickname] = useState(false);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();
  const [sidePanelExpanded, setSidePanelExpanded] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const ctx = useContext(AppContext);

  const getAuditLogs = async () => {
    const res = await api.audit.list('CASE', id as string);
    if (res.data) {
      setAuditLogs(res.data);
    }
  };

  const getCase = async () => {
    const res = await api.cases.get(id as string);
    if (res.data) {
      setCase(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getCase();
      getAuditLogs();
    });
  }, [id]);

  const updateCase = (updates: Partial<Case>) => {
    // @ts-ignore
    setCase({ ..._case, ...updates });
    getAuditLogs();
  };

  const refreshAuditLogs = () => {
    getAuditLogs();
  };

  const handleNicknameSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const res = await api.cases.updateCaseNickname(id as string, _case?.nickname || '');
    if (!res.error) {
      setEditingNickname(false);
    }
  };

  // TODO: canEdit if status === DRAFT && userHasAccess

  let activeStep: { processStepId: string; id?: string } | undefined = getActiveCaseStep(_case);

  if (!activeStep && _case?.status === 'DRAFT') {
    activeStep = _case.process.steps[0] ? { processStepId: _case.process.steps[0].id } : undefined;
  }

  const stepsWithCompletion: any = _case?.process.steps.map((processStep) => {
    const caseStep = _case.caseSteps.find((caseStep) => caseStep.processStepId === processStep.id);

    return {
      ...processStep,
      isCompleted: !!caseStep?.completedAt,
    };
  });

  const [publishInfo, setPublishInfo] = useState<{
    canPublish: boolean;
    publishRequirements: string[];
  }>({ canPublish: true, publishRequirements: [] });

  useEffect(() => {
    if (!_case) return;
    const { canPublish, publishRequirements } = calculatePublishRequirements(_case);
    if (!publishInfo.canPublish && canPublish === true) {
      ctx.setToast({ message: `Case ready to publish!`, type: 'success', clearAfterMs: 3000 });
    }
    setPublishInfo({ canPublish, publishRequirements });
  }, [_case]);

  const publishCase = async () => {
    if (!_case) return;
    const res = await api.cases.publish(_case.id);
    if (res.data) {
      updateCase(res.data);
      ctx.setToast({ message: `Case published!`, type: 'success', clearAfterMs: 3000 });
    }
  };

  const { canPublish, publishRequirements } = publishInfo;

  let itemNamePrefix = _case?.process.maxItems === 1 ? '' : 's';
  if (_case?.process.itemName.slice(-1) === 's') {
    itemNamePrefix = '';
  }
  const itemName = title(`${_case?.process.itemName}${itemNamePrefix}`);

  // Add useEffect to handle viewport width changes
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setSidePanelExpanded(false);
      } else if (window.innerWidth > 1400) {
        setSidePanelExpanded(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTabSelect = (index: number) => {
    setSelectedTabIndex(index);
    if (!sidePanelExpanded) {
      setSidePanelExpanded(true);
    }
  };

  const { subscribe } = useWebSocket(id as string);

  useEffect(() => {
    // Subscribe to case status updates
    const unsubscribe = subscribe('case.step.updated', (data) => {
      getCase();
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  return (
    <main className="content">
      {!!_case && (
        <div className="view-case__container">
          <div className="view-case__main-panel">
            <div className="box view-case__header" ref={ref}>
              <div className="view-case__header-main">
                <div>
                  <div className="view-case__process-name-container">
                    <p className="view-case__process-name">{_case.process.taxonomyLabel ? `${_case.process.taxonomyLabel} ${_case.process.name}` : _case.process.name}</p>
                    {!editingNickname && (
                      <button
                        style={{ marginBottom: '8px' }}
                        className="link-button"
                        onClick={() => setEditingNickname(true)}
                      >
                        {_case.nickname ? 'Update case nickname' : 'Set case nickname'}
                        <PencilIcon />
                      </button>
                    )}
                    {!!editingNickname && (
                      <form className="view-case__nickname-form" onSubmit={handleNicknameSubmit}>
                        <Input
                          id="name"
                          small={true}
                          labelText=""
                          placeholder="Nickname"
                          value={_case.nickname || ''}
                          onChange={(nickname) => setCase({ ..._case, nickname })}
                        />
                        <button
                          className="icon-button view-case__nickname-form-button"
                          title="Save"
                        >
                          <CheckCircleIcon />
                        </button>
                      </form>
                    )}
                  </div>
                  <div className="view-case__code-container">
                    <h1 className="view-case__code">
                      {_case.nickname ? (
                        <Fragment>
                          {_case.nickname}{' '}
                          <span className="view-case__code--small">({_case.code})</span>
                        </Fragment>
                      ) : (
                        _case.code
                      )}
                    </h1>
                    <div style={{ paddingBottom: '8px' }}>
                      <CaseStatusChip caseStatus={_case.status} />
                    </div>
                  </div>
                </div>
                <CaseStatus _case={_case} />
              </div>
              <div className="view-case__header-actions">
                {_case.status === 'DRAFT' && (
                  <button
                    disabled={!canPublish}
                    className="button button--small"
                    onClick={publishCase}
                  >
                    Publish
                  </button>
                )}
                {!!publishRequirements.length && (
                  <InfoBanner
                    variant="warning"
                    icon={<ExclamationTriangleIcon />}
                    message={`Case must have ${lower(publishRequirements.join(', '))}`}
                  />
                )}
                {!publishRequirements.length && _case.status === 'DRAFT' && (
                  <InfoBanner
                    variant="success"
                    icon={<CheckCircleIcon />}
                    message="Case ready to publish!"
                  />
                )}
              </div>
            </div>
            <div className="view-case__contents">
              {/* <div className="box">
                <CaseItems
                  caseId={id as string}
                  caseItems={_case.items}
                  updateCase={updateCase}
                  process={_case.process}
                  canAdd={true}
                  width={
                    ref.current?.offsetWidth
                      ? (ref.current?.offsetWidth - 32)?.toString() + 'px'
                      : '500px'
                  }
                />
              </div> */}
              <div className="box view-case__bottom-panel" ref={bottomPanelRef}>
                <Tabs>
                  <TabList>
                    {_case.status === 'ACTIVE' && <Tab>Current Step</Tab>}
                    {_case.status !== 'DRAFT' && <Tab>Step History</Tab>}
                    <Tab>{itemName}</Tab>
                    {!!_case.process.supportingData.length && <Tab>Supporting Info</Tab>}
                    <Tab>Process</Tab>
                    <Tab>Documents</Tab>
                  </TabList>
                  {_case.status === 'ACTIVE' && (
                    <TabPanel>
                      <CaseStep
                        _case={_case}
                        caseStepId={activeStep?.id as string}
                        updateCase={updateCase}
                      />
                    </TabPanel>
                  )}
                  {_case.status !== 'DRAFT' && (
                    <TabPanel>
                      <CaseStepHistory
                        _case={_case}
                      />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <CaseItemsV2
                      caseId={id as string}
                      caseItems={_case.items}
                      process={_case.process}
                      canAdd={true}
                      updateCase={updateCase}
                      caseCompleted={_case.status === 'COMPLETED'}
                      casePublished={_case.status !== 'DRAFT'}
                    />
                  </TabPanel>
                  {!!_case.process.supportingData.length && (
                    <TabPanel>
                      <SupportingInfo
                        caseId={id as string}
                        updateCase={updateCase}
                        supportingCaseData={_case.supportingData}
                        supportingDataFields={_case.process.supportingData}
                        editOnRender={_case.status === 'DRAFT'}
                      />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <ProcessMap
                      processSteps={stepsWithCompletion}
                      parentRef={bottomPanelRef}
                      activeStepId={activeStep?.processStepId}
                      onStepSelect={(processStepId) => {
                        let caseStep = _case.caseSteps.find((caseStep) => caseStep.processStepId === processStepId);

                        if (!caseStep) {
                          caseStep = {
                            id: `${processStepId}_new`,
                            processStepId,
                            completedAt: undefined,
                            decision: undefined,
                            dueDate: undefined,
                            caseId: _case.id,
                            companyId: _case.companyId,
                            createdAt: new Date(),
                            stepStartedDate: new Date(),
                            supportingData: {},
                          }
                        };

                        setSelectedStepId(caseStep?.id);
                      }}

                    />
                  </TabPanel>
                  <TabPanel>
                    <CaseDocuments caseId={id as string} refreshAuditLogs={refreshAuditLogs} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className={`box view-case__side-panel ${!sidePanelExpanded ? 'view-case__side-panel--collapsed' : ''}`}>
            <button 
              className="view-case__side-panel-toggle" 
              onClick={() => setSidePanelExpanded(!sidePanelExpanded)}
              title={sidePanelExpanded ? "Collapse side panel" : "Expand side panel"}
            >
              {sidePanelExpanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </button>
            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
              <TabList>
                <Tab>{sidePanelExpanded ? 'Users' : 'U'}</Tab>
                {_case.status !== 'DRAFT' && <Tab>{sidePanelExpanded ? 'Data' : 'D'}</Tab>}
                <Tab>{sidePanelExpanded ? 'Notes' : 'N'}</Tab>
                <Tab>{sidePanelExpanded ? 'Audit' : 'A'}</Tab>
              </TabList>

              {sidePanelExpanded && (
                <Fragment>
                  <TabPanel>
                    <CaseUsers
                      caseId={id as string}
                      caseUsersInProcess={_case.caseUsers}
                      ownerGroups={_case.process.ownerGroups}
                      canEdit={true}
                      updateCase={updateCase}
                    />
                  </TabPanel>
                  {_case.status !== 'DRAFT' && (
                    <TabPanel>
                      <CaseData _case={_case} />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <CaseNotes caseId={id as string} />
                  </TabPanel>
                  <TabPanel>
                    <AuditList
                      entityType="CASE"
                      entityId={id as string}
                      preloadedAuditLogs={auditLogs}
                    />
                  </TabPanel>
                </Fragment>
              )}
            </Tabs>
          </div>
        </div>
      )}
      <Modal
        isOpen={!!selectedStepId}
        handleClose={() => setSelectedStepId(undefined)}
        modalStyles={{ maxWidth: '1000px' }}
      >
        {!!selectedStepId && !!_case && (
          <CaseStep
            _case={_case}
            caseStepId={selectedStepId}
            updateCase={() => {}}
            
          />
        )}
      </Modal>
    </main>
  );
};

export default ViewCasePage;
