import React, { useEffect } from 'react';
import './FileAttachments.css';

interface FileWithPreview extends File {
  preview?: string;
  name: string;
}

interface FileAttachmentsProps {
  files: FileWithPreview[];
  onRemoveFile: (index: number) => void;
  className?: string;
}

const FileAttachments: React.FC<FileAttachmentsProps> = ({ 
  files, 
  onRemoveFile,
  className = '' 
}) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <div className={`attached-files ${className}`}>
      {files.map((file, index) => (
        <div key={index} className="file-item">
          <div className={`file-icon ${file.preview}`}></div>
          <span className="file-name" title={file.name || 'Unnamed file'}>
            {file.name || 'Unnamed file'}
          </span>
          <button 
            className="remove-file" 
            onClick={() => onRemoveFile(index)}
            aria-label="Remove file"
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};

export default FileAttachments; 