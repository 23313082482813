import { ArrowRightIcon, BoltIcon, CheckCircleIcon, ClockIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { Step } from '../../api/process';
import { ownerGroupToTitleCase } from '../../modules/case/utils';
import ProcessitemsIcon from '../icon/ProcessItems';
import InternalControlIcon from '../icon/InternalControl';
import DataCollectedIcon from '../icon/DataCollected';
import WorkInstructionIcon from '../icon/WorkInstructionIcon';
import { sentence } from 'case';
import { caculateOverallRag } from '../../modules/admin/process-builder/edit-step/step-risks/utils';

interface Props {
  step: Step & {
    current?: boolean;
    isCompleted?: boolean;
  };
}

const DecisionStep: React.FC<Props> = ({ step }) => {
  const preMitigationRag = caculateOverallRag(step.risks || [], true);
  const postMitigationRag = caculateOverallRag(step.risks || [], false);

  let hasMitigationRag = false;
  step.risks?.forEach((risk) => {
    if (risk.postMitigationRag) {
      hasMitigationRag = true;
    }
  });

  return (
    <div
      className={`decision-node ${step.current ? 'decision-node--current' : ''} ${
        step.isCompleted ? 'step-node--completed' : ''
      }`}
    >
      <div className="decision-node__text">
        <span>
          {step.taxonomyLabel && step.taxonomyLabel + ' '}
          {step.name}
        </span>
        <ul className="step-node__owners">
          {step.ownerGroups.map((ownerGroup) => {
            return (
              <li key={ownerGroup} className="step-node__owner">
                {ownerGroupToTitleCase(ownerGroup)}
              </li>
            );
          })}
        </ul>
        <div className="step-node__icons">
          {!!step.isCompleted && (
            <CheckCircleIcon className="step-node__icon" style={{ stroke: 'var(--success)' }} />
          )}
        </div>
        <div className="step-node__attributes step-node__attributes--decision">
          {!!step.automation?.agent && <SparklesIcon className="step-node__icon step-node__icon--agent" />}
          {step.type === 'CONTROL' && <InternalControlIcon className="step-node__icon" />}
          {!!step.guidanceNotes && <WorkInstructionIcon className="step-node__icon" />}
          {!!step.supportingData?.length && step.type !== 'CONTROL' && (
            <DataCollectedIcon className="step-node__icon" />
          )}
          {!!step.automation && !step.automation?.agent && <BoltIcon className="control-step__icon" />}
          {!!step.dueInSentence && <ClockIcon className="step-node__icon" />}
          {step.requireProcessItemsHere && <ProcessitemsIcon className="step-node__icon" />}
        </div>
      </div>
      <div className="step-node__rag-container step-node__rag-container--decision">
        {!!preMitigationRag && (
          <div
            className={`step-node__rag step-node__rag--${preMitigationRag.toLowerCase()}`}
            title={`Risk RAG: ${sentence(preMitigationRag)}`}
          ></div>
        )}
        {hasMitigationRag && <ArrowRightIcon className="step-node__rag-arrow" />}
        {hasMitigationRag && !!postMitigationRag && (
          <div
            className={`step-node__rag step-node__rag--${postMitigationRag.toLowerCase()}`}
            title={`Post Mitigation Risk RAG: ${sentence(postMitigationRag)}`}
          ></div>
        )}
      </div>
    </div>
  );
};

export default DecisionStep;
