import { Edge, Node, Position, MarkerType, HandleType } from 'reactflow';
import { Step } from '../../api/process';

// const initialNodes: Node[] = [
//   { id: '1', position: { x: 0, y: 0 }, sourcePosition: Position.Right, data: { label: 'Receive request from a man in a hat' } },
//   { id: '2', position: { x: 200, y: 0 }, type: 'decisionNode', sourcePosition: Position.Right, targetPosition: Position.Top, data: { label: 'Does it smell?' } },
//   { id: '3', position: { x: 400, y: 0 }, sourcePosition: Position.Right, targetPosition: Position.Left, data: { label: '2' } },

//   { id: '4', position: { x: 400, y: 200 }, sourcePosition: Position.Right, targetPosition: Position.Left, data: { label: '2' } },
// ];
// const initialEdges: Edge[] = [
//   { id: 'e1-2', source: '1', target: '2', markerEnd: { type: MarkerType.ArrowClosed }, targetHandle: 'a', },
//   { id: 'e2-3', source: '2', target: '3', markerEnd: { type: MarkerType.ArrowClosed }, sourceHandle: 'b', targetHandle: 'b', label: 'Yes' },
//   { id: 'e2-4', source: '2', target: '4', markerEnd: { type: MarkerType.ArrowClosed }, sourceHandle: 'c', targetHandle: 'b', label: 'No' }
// ];

export const TRACK_HEIGHT = 300;
export const STEP_WIDTH = 250;

export const generateNodesFromSteps = (processSteps: Step[], activeStepId?: string) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const processStepsWithAvailableEdges = processSteps.map((step) => {
    return {
      ...step,
      availableEdges: ['top', 'left', 'right', 'bottom'],
    };
  });

  processStepsWithAvailableEdges.reverse().forEach((step, index) => {
    const { node, edges: newEdges } = generateNodeAndEdge(step, processSteps, index, activeStepId);

    if (node) nodes.push(node);
    if (newEdges.length) edges.push(...newEdges);
  });

  return {
    nodes,
    edges,
  };
};

const generateNodeAndEdge = (currentStep: Step, allSteps: Step[], index: number, activeStepId?: string) => {
  let yPosition = currentStep.track * TRACK_HEIGHT;
  let xPosition = (allSteps.length - index) * STEP_WIDTH

  let current = false;
  if (currentStep.id === activeStepId) {
    current = true;
  }

  if (currentStep.mapPosition) {    
    xPosition = currentStep.mapPosition.x;
    yPosition = currentStep.mapPosition.y;
  }  

  const nextSteps = getNextSteps(allSteps, currentStep.id);
  const previousSteps = getPreviousSteps(allSteps, currentStep);

  const node: Node = {
    id: currentStep.id,
    position: { x: xPosition, y: yPosition },
    data: { label: currentStep.name, step: { ...currentStep, current }, nextSteps: nextSteps || [], previousSteps: previousSteps || [] },
    type: currentStep.type === 'DECISION' ? 'decision' : 'step',
  };

  let edges: Edge[] = [];
  if (!currentStep.previousSteps) return { node, edges };

  currentStep.previousSteps.forEach((previousStepConnection, index) => {
    const previousStep = allSteps.find((step) => step.id === previousStepConnection.id);
    if (!previousStep) return;

    const edge: Edge = {
      id: `e${currentStep.stepNumber - 1}_${currentStep.stepNumber}_${currentStep.id}_${previousStep.id}_${previousStepConnection.stepEdge}_${previousStepConnection.previousStepEdge}`,
      source: previousStep.id,
      target: currentStep.id,
      markerEnd: { type: MarkerType.ArrowClosed },
      type: 'smoothstep',
      sourceHandle: previousStepConnection.stepEdge,
      targetHandle: previousStepConnection.previousStepEdge,
      label: previousStepConnection.label,
    };


    edges.push(edge);
  });

  // ;

  return {
    node,
    edges,
  };
};

export const getNextSteps = (processSteps: Step[], currentProcessStepId: string) => {
  const nextSteps = processSteps.filter((step) => {
    return step.previousSteps?.find((previousStep) => previousStep.id === currentProcessStepId);
  });

  return nextSteps;
};

export const getPreviousSteps = (processSteps: Step[], currentStep: Step) => {
  const previousStepIds = currentStep.previousSteps?.map((previousStep) => previousStep.id)
  const previousSteps = processSteps.filter((step) => {
    return previousStepIds?.includes(step.id)
  });
  return previousSteps;
}

export const getAllPreviousSteps = (processSteps: Step[], currentStep: Step, alreadyProcessedStepIds: string[]) => {
  const previousSteps: Step[] = [];
  const directPreviousSteps = getPreviousSteps(processSteps, currentStep);
  directPreviousSteps.forEach((step) => {
    if (alreadyProcessedStepIds.includes(step.id)) {
      return
    }
    previousSteps.push(step);
    alreadyProcessedStepIds.push(step.id)
    const nestedPreviousSteps = getAllPreviousSteps(processSteps, step, alreadyProcessedStepIds);

    previousSteps.push(...nestedPreviousSteps);
    alreadyProcessedStepIds.push(...nestedPreviousSteps.map((step) => step.id));
  });  


  return previousSteps;
}

export const calculateHandles = (data: { step: Step; label: string; nextSteps: Step[]; previousSteps: Step[] }) => {
  let hasLeftHandle: HandleType | undefined = undefined;
  let hasTopHandle: HandleType | undefined = undefined;
  let hasRightHandle: HandleType | undefined = undefined;
  let hasBottomHandle: HandleType | undefined = undefined;

  // previous steps will be target?
  data.previousSteps.forEach((step) => {
    const connectedStep = data.step.previousSteps?.find((s) => s.id === step.id);
    if (!connectedStep) return;

    switch (connectedStep.previousStepEdge) {
      case Position.Left:
        hasLeftHandle = 'target';
        break;
      case Position.Top:
        hasTopHandle = 'target';
        break;
      case Position.Right:
        hasRightHandle = 'target';
        break;
      case Position.Bottom:
        hasBottomHandle = 'target';
        break;
    }
  });

  // next steps will be source
  data.nextSteps.forEach((step) => {
    const connectedStep = step.previousSteps?.find((s) => s.id === data.step.id);
    if (!connectedStep) return;

    switch (connectedStep.stepEdge) {
      case Position.Left:
        hasLeftHandle = 'source';
        break;
      case Position.Top:
        hasTopHandle = 'source';
        break;
      case Position.Right:
        hasRightHandle = 'source';
        break;
      case Position.Bottom:
        hasBottomHandle = 'source';
        break;
    }
  });

  return {
    hasLeftHandle,
    hasTopHandle,
    hasRightHandle,
    hasBottomHandle,
  }
}

export const getIdsFromEdgeId = (edgeId: string) => {
  const [previousStepNumber, currentStepNumber, currentStepId, previousStepId] = edgeId.split('_');
  return {
    previousStepNumber,
    currentStepNumber,
    currentStepId,
    previousStepId,
  }
}
