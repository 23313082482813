import { Case } from '../../../api/case';
import './CaseStatus.css';
import { useContext} from 'react';
import { AppContext } from '../../../App';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import DueDate from '../../../components/due-date/DueDate';

interface Props {
  _case: Case;
}

const CaseStatus: React.FC<Props> = ({ _case }) => {
  const ctx = useContext(AppContext);

  const completionPercentage = _case.status === 'COMPLETED' ? 1 : _case.completionPercentage;

  return (
    <div className="case-status box">
      <div>
        <div className="case-status__completion-percentage">
          <CircularProgressbarWithChildren
            value={completionPercentage * 100}
            styles={{
              path: { stroke: 'var(--green)' },
              text: { fill: 'var(--text)', fontSize: '18px' },
            }}
          >
            <p className="case-status__completion-percentage-text">{Math.round(completionPercentage * 100)}%</p>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      <div>
        {!!_case.dueDate && (
          <div>
            <p style={{ fontSize: '12px' }}>Due Date</p>
            <DueDate date={_case.dueDate} />
          </div>
        )}
        {!_case.dueDate && (
          <div>
            <p style={{ fontSize: '12px' }}>Due Date</p>
            <p style={{ color: 'var(--gray-400)' }}>Not set</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseStatus;
