import { v4 as uuid } from 'uuid';
import { Process, ItemLookupIntegration } from '../../../../api/process';
import { Fragment, useEffect, useState } from 'react';
import Select from '../../../../components/input/Select';
import './ConfigureAutomatedLookup.css';
import DataMappingInput from './DataMappingInput';
import { DataMapping } from '../../../../api/integration/admin';

interface Props {
  process: Process;
  onSave: (process: Process) => void | Promise<void>;
}

const ConfigureAutomatedLookup: React.FC<Props> = ({ process, onSave }) => {
  const [lookupIntegration, setLookupIntegration] = useState<ItemLookupIntegration>({
    id: process.itemLookupIntegration?.id || uuid(),
    integrationType: process.itemLookupIntegration?.integrationType || 'DEMO',
    lookupItemFieldName: process.itemLookupIntegration?.lookupItemFieldName || '',
    dataMappings: process.itemLookupIntegration?.dataMappings || [],
  });

  const lookupFieldOptions = process.itemFields.map((field) => ({
    label: field.label || field.name,
    value: field.name,
  }));

  useEffect(() => {
    if (process.itemLookupIntegration) {
      setLookupIntegration({
        ...lookupIntegration,
        ...(process.itemLookupIntegration || {}),
        dataMappings: process.itemLookupIntegration?.dataMappings || [],
      });
    }
  }, [process.itemLookupIntegration]);

  useEffect(() => {
    if (!lookupIntegration.dataMappings?.length) {
      handleAddDataMapping();
    }
  }, [lookupIntegration.dataMappings]);

  const availableIncomingFields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'dateOfBirth',
    'startDate',
    'jobTitle',
    'department',
    'managerId',
    'status',
    'location.officeName',
    'location.addressLine1',
    'location.addressLine2',
    'location.town',
    'location.county',
    'location.postcode',
    'location.country',
    'sortCode',
    'accountNumber',
  ];

  const handleAddDataMapping = () => {
    const newDataMapping: DataMapping = {
      id: uuid(),
      inputKey: '',
      outputKey: '',
    };

    const dataMappings = [...(lookupIntegration.dataMappings || []), newDataMapping];

    setLookupIntegration({ ...lookupIntegration, dataMappings });
  };

  const handleDataMappingChange = (dataMappingToUpdate: DataMapping) => {
    const updatedDataMappings =
      lookupIntegration.dataMappings?.map((dataMapping) => {
        if (dataMappingToUpdate.id === dataMapping.id) {
          return dataMappingToUpdate;
        }

        return dataMapping;
      }) || [];

    setLookupIntegration({ ...lookupIntegration, dataMappings: updatedDataMappings });
  };

  const handleDataMappingDelete = (dataMappingToDelete: DataMapping) => {
    const updatedDataMappings =
      lookupIntegration.dataMappings?.filter(
        (dataMapping) => dataMapping.id !== dataMappingToDelete.id,
      ) || [];
    setLookupIntegration({ ...lookupIntegration, dataMappings: updatedDataMappings });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSave({...process, itemLookupIntegration: lookupIntegration});
  };

  const isFormValid = lookupIntegration.dataMappings?.every((dataMapping) => dataMapping.inputKey && dataMapping.outputKey) && lookupIntegration.lookupItemFieldName;

  return (
    <Fragment>
      <h2 style={{ margin: 0, marginBottom: '8px' }}>Retrieve items from an external source</h2>
      <div className="configure-automated-lookup">
        <form className="configure-automated-lookup__form" onSubmit={handleFormSubmit}>
          <Select
            id="lookup-integration-type"
            labelText="Lookup Integration Type"
            options={[{ label: 'HR System', value: 'DEMO' }]}
            value={lookupIntegration.integrationType}
            onChange={(integrationType) =>
              setLookupIntegration({
                ...lookupIntegration,
                integrationType: integrationType as 'DEMO',
              })
            }
          />
          <Select
            id="lookup-item-field"
            labelText="Lookup Item Field"
            options={lookupFieldOptions}
            value={lookupIntegration.lookupItemFieldName}
            onChange={(lookupItemFieldName) =>
              setLookupIntegration({ ...lookupIntegration, lookupItemFieldName })
            }
            helpText="Select the field that will be used to lookup items in the external source"
          />
          <div className="integration-form__data-mapping">
            <h3>Map your data to a case item field</h3>
            {lookupIntegration.dataMappings?.map((dataMapping) => {
              return (
                <DataMappingInput
                  key={dataMapping.id}
                  dataMapping={dataMapping}
                  onChange={handleDataMappingChange}
                  onDelete={handleDataMappingDelete}
                  excludeOutputBaseFields={['supportingData']}
                  availableFields={{
                    items: process.itemFields,
                  }}
                  availableIncomingFields={lookupIntegration.integrationType === 'DEMO' ? availableIncomingFields : undefined}
                />
              );
            })}
            <button
              onClick={handleAddDataMapping}
              className="button button--secondary button--small"
              type="button"
            >
              Add data mapping
            </button>
          </div>
          <button type="submit" className="button button--primary" style={{ marginTop: '32px' }} disabled={!isFormValid}>
            Save
          </button>
        </form>
        {lookupIntegration.integrationType === 'DEMO' && (
          <div className="configure-automated-lookup__demo-data">
            <h3>Available Data for Mapping</h3>
            <p>The following data is available in the HR System</p>
            <ul>
              <li>id</li>
              <li>firstName</li>
              <li>lastName</li>
              <li>email</li>
              <li>phoneNumber</li>
              <li>dateOfBirth</li>
              <li>startDate</li>
              <li>jobTitle</li>
              <li>department</li>
              <li>managerId</li>
              <li>status</li>
              <li>location.officeName</li>
              <li>location.addressLine1</li>
              <li>location.addressLine2</li>
              <li>location.town</li>
              <li>location.county</li>
              <li>location.postcode</li>
              <li>location.country</li>
              <li>sortCode</li>
              <li>accountNumber</li>
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ConfigureAutomatedLookup;
